export const sortArray = (array, order, orderBy) => (
    array.sort((a, b) => {
            let comparison = 0;
            if (a[orderBy] > b[orderBy]) {
                comparison = order === 'asc' ? 1 : -1;
            } else if (a[orderBy] < b[orderBy]) {
                comparison = order === 'asc' ? -1 : 1;
            }
            return comparison;
        }
    )
);