import React, {useContext, useEffect, useRef, useState} from 'react';
import momentTimezone from "moment-timezone";
import api from "../../../api";
import localization from "../../../localization";
import {store} from "../../../store";
import {sortArray} from '../../../utils/sortingService';
import Dropdown from "../../../components/content/Dropdown";
import ModalConfirmation from "../../../components/modal/ModalConfirmation";
import SearchInput from "../../../components/form/SearchInput";
import ResourcesCreate from "./ResourcesCreate";
import EmptyPage from "./EmptyPage";
import moment from "moment";
import ResourcesFolder from "./ResourcesFolder";


const Resources = ({webinarId, resourceUploadAccess, loggedInUserEventParticipant, selectPerson}) => {
    const [rootId, setRootId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [resources, setResources] = useState([]);
    const [rootResources, setRootResources] = useState([]);
    const [folder, setFolder] = useState(null);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [errorToast, setErrorToast] = useState(null);
    const [video, setVideo] = useState(null);
    const [confirmRemove, setConfirmRemove] = useState(null);
    const [search, setSearch] = useState('');
    const [createEditResource, setCreateEditResource] = useState(false);
    const [createEditFolder, setCreateEditFolder] = useState(false);
    const {currentUser, eventNotifications, setEventNotifications} = useContext(store);
    const resourcesContainer = useRef();
    useEffect(() => {
        const notifications = eventNotifications.filter(({objectId, notificationsType}) => objectId === webinarId && notificationsType === 'EVENT_RESOURCE');
        if (notifications.length) {
            api.put(`/api/v1/notifications/read`, {notificationsType: 'EVENT_RESOURCE', objectId: webinarId}).then(({data}) => {
                api.get(`/api/v1/notifications/unread`).then(({data}) => {
                    setEventNotifications(data);
                });
            });
        }
    }, []);
    useEffect(() => {
        api.post(`/api/v1/event/${webinarId}/resource`).then(({data}) => {
            if (data.length) {
                setRootId(data[0].id);
                setCategoryId(data[0].id);
                const resources = sortArray([...data.filter(({name}) => name !== 'Root'), ...data[0].resourceLinks].map((item) => ({...item, time: moment(item.lastModifiedDateStr).toDate().getTime()})), 'desc', 'time');
                setRootResources(resources);
                setResources(resources);
            }
        });
    }, []);
    useEffect(() => {
        if (resourcesContainer && resourcesContainer.current) {
            resourcesContainer.current.scrollTop = resourcesContainer.current.scrollHeight;
        }
    }, [resources]);
    const filteredResources = resources.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    return (
        <div className='resources'>
            <div className='flex-between'>
                <SearchInput
                    value={search}
                    onChange={(value) => setSearch(value)}
                    placeholder={localization.webinarResourcesSearch}
                />
                {(!resourceUploadAccess || loggedInUserEventParticipant.host || loggedInUserEventParticipant.admin) ? (
                    <Dropdown
                        button={(
                            <button className='action-button'>
                                {localization.webinarResourcesAdd}
                            </button>
                        )}
                    >
                        {!folder ? (
                            <button
                                onClick={() => setCreateEditFolder({})}
                            >
                                <img alt='logo' src='/images/folder.png'/>
                                {localization.webinarResourcesFolder}
                            </button>
                        ) : null}
                        <button
                            onClick={() => setCreateEditResource({})}
                        >
                            <img alt='logo' src='/images/file.png'/>
                            {localization.webinarResourcesFile}
                        </button>
                    </Dropdown>
                ) : null}
            </div>
            <div className='resources--header'>
                {folder ? (
                    <div>
                        <a onClick={() => {
                            setCategoryId(rootId);
                            setResources(rootResources);
                            setFolder(null)
                        }}>
                            {localization.formatString(localization.webinarResourcesShowing, rootResources.length)}
                        </a>
                        {` > ${folder.name} > ${localization.formatString(localization.webinarResourcesShowing, filteredResources.length)}`}
                    </div>
                ) : (
                    localization.formatString(localization.webinarResourcesShowing, filteredResources.length)
                )}
            </div>
            {resources.length ? (
                <div className='resources--content'>
                    <table>
                        <thead>
                        <tr>
                            <td>{localization.webinarResourcesTableName}</td>
                            <td>{localization.webinarResourcesTableUploaded}</td>
                            <td>{localization.webinarResourcesTableUpload}</td>
                            <td>{localization.webinarResourcesTableRelated}</td>
                            {loggedInUserEventParticipant.host || loggedInUserEventParticipant.admin ? (
                                <td>{localization.webinarResourcesTableAction}</td>
                            ) : null}
                        </tr>
                        </thead>
                        <tbody>
                        {filteredResources.map((item) => (
                            <tr>
                                <td>
                                    {!item.resourceType ? (
                                        <a
                                            className='resources--link'
                                            onClick={() => {
                                                setFolder(item);
                                                setCategoryId(item.id);
                                                api.get(`/api/v1/event/${webinarId}/resource/category/${item.id}/objects`).then(({data}) => {
                                                    const resources = sortArray(data.map((item) => ({...item, time: moment(item.lastModifiedDateStr).toDate().getTime()})), 'desc', 'time');
                                                    setResources(resources);
                                                });
                                            }}
                                        >
                                            <img alt='logo' src='/images/resources-folder.svg'/>
                                            {item.name}
                                        </a>
                                    ) : null}
                                    {item.resourceType === 'VIDEO' ? (
                                        <a
                                            className='resources--link'
                                            onClick={() => setVideo(item.vimeoUploadVideoResponse)}
                                        >
                                            <img alt='logo' src='/images/resources-video.svg'/>
                                            {item.name}
                                        </a>
                                    ) : null}
                                    {item.resourceType === 'PDF' ? (
                                        <a
                                            className='resources--link'
                                            href={item.url}
                                            target='_blank'
                                        >
                                            <img alt='logo' src='/images/resources-pdf.svg'/>
                                            {item.name}
                                        </a>
                                    ) : null}
                                    {item.resourceType === 'DOCX' ? (
                                        <a
                                            className='resources--link'
                                            href={item.url}
                                            target='_blank'
                                        >
                                            <img alt='logo' src='/images/word.png'/>
                                            {item.name}
                                        </a>
                                    ) : null}
                                    {item.resourceType === 'URL' ? (
                                        <a
                                            className='resources--link'
                                            href={(item.url.indexOf('http://') < 0 && item.url.indexOf('https://') < 0) ? `https://${item.url}` : item.url}
                                            target='_blank'
                                        >
                                            <img alt='logo' src='/images/resources-link.svg'/>
                                            {item.name}
                                        </a>
                                    ) : null}
                                </td>
                                <td>{item.createdById === currentUser.id ? localization.webinarResourcesMe : item.createdByName}</td>
                                <td>{momentTimezone(item.createdDateStr).tz(currentUser.timeZoneId).format('MMM DD, YYYY')}</td>
                                <td>
                                    <div className='resources--tags'>
                                        {item.tags ? (item.tags.split(',').map((tag) => (
                                            <div key={tag}>
                                                {tag}
                                            </div>
                                        ))) : null}
                                    </div>
                                </td>
                                {loggedInUserEventParticipant.host || loggedInUserEventParticipant.admin ? (
                                    <td>
                                        <Dropdown
                                            className='actions-dropdown'
                                            button={(<img alt='dropdown' src='/images/dropdown.svg' />)}
                                        >
                                            <button
                                                onClick={() => {
                                                    if (item.resourceType) {
                                                        setCreateEditResource(item);
                                                    } else {
                                                        setCreateEditFolder(item);
                                                    }
                                                }}
                                            >
                                                {localization.webinarResourcesEdit}
                                            </button>
                                            <button
                                                className='red'
                                                onClick={() => {
                                                    setConfirmRemove(item);
                                                }}
                                            >
                                                {localization.webinarResourcesDelete}
                                            </button>
                                        </Dropdown>
                                    </td>
                                ) : null}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyPage
                    title={localization.webinarResourcesEmptyTitle}
                    description={localization.webinarResourcesEmptyDescription}
                    actionLabel={localization.webinarResourcesEmptyAction}
                    actionHandler={(!resourceUploadAccess || loggedInUserEventParticipant.host || loggedInUserEventParticipant.admin) ? () => setCreateEditResource({}) : null}
                />
            )}
            {createEditResource ? (
                <ResourcesCreate
                    handleClose={() => setCreateEditResource(null)}
                    setErrorToast={setErrorToast}
                    setConfirmationToast={setConfirmationToast}
                    webinarId={webinarId}
                    categoryId={categoryId}
                    resource={createEditResource}
                    onSubmit={(data) => {
                        if (createEditResource.id) {
                            setResources(resources.map((item) => item.resourceType && item.id === data.id ? data : item))
                        } else {
                            setResources([data, ...resources]);
                        }
                        if (!folder) {
                            if (createEditResource.id) {
                                setRootResources(resources.map((item) => item.resourceType && item.id === data.id ? data : item))
                            } else {
                                setRootResources([data, ...resources]);
                            }
                        }
                    }}
                />
            ) : null}
            {createEditFolder ? (
                <ResourcesFolder
                    handleClose={() => setCreateEditFolder(null)}
                    setErrorToast={setErrorToast}
                    setConfirmationToast={setConfirmationToast}
                    webinarId={webinarId}
                    folder={createEditFolder}
                    onSubmit={(data) => {
                        if (createEditFolder.id) {
                            setResources(resources.map((item) => !item.resourceType && item.id === createEditFolder.id ? ({...item, ...data}) : item))
                        } else {
                            setResources([data, ...resources]);
                        }
                        if (!folder) {
                            if (createEditFolder.id) {
                                setRootResources(resources.map((item) => !item.resourceType && item.id === createEditFolder.id ? ({...item, ...data}) : item))
                            } else {
                                setRootResources([data, ...resources]);
                            }
                        }
                    }}
                />
            ) : null}
            {confirmRemove ? (
                <ModalConfirmation
                    title={localization.webinarResourcesDeleteTitle}
                    description={localization.webinarResourcesDeleteDescription}
                    confirm={localization.webinarResourcesDeleteOk}
                    cancel={localization.webinarResourcesDeleteCancel}
                    onCancel={() => setConfirmRemove(null)}
                    onConfirm={() => {
                        if (confirmRemove.resourceType) {
                            api.delete(`/api/v1/event/${webinarId}/resource/category/${categoryId}/link/${confirmRemove.id}`).then(({data}) => {
                                setResources(resources.filter(({id}) => confirmRemove.id !== id));
                                setConfirmationToast(`${confirmRemove.name} ${localization.webinarResourcesDeleteConfirmation}`);
                                setTimeout(() => setConfirmationToast(null), 3000);
                                setConfirmRemove(null);
                            });
                        } else {
                            api.delete(`/api/v1/event/${webinarId}/resource/category/${confirmRemove.id}`).then(({data}) => {
                                setResources(resources.filter(({id, resourceType}) => resourceType || confirmRemove.id !== id));
                                setConfirmationToast(`${confirmRemove.name} ${localization.webinarResourcesDeleteConfirmation}`);
                                setTimeout(() => setConfirmationToast(null), 3000);
                                setConfirmRemove(null);
                            });
                        }
                    }}
                />
            ) : null}
            {video ? (
                <div className='video'>
                    <div
                        className='video--close'
                        onClick={() => setVideo(null)}
                    >×</div>
                    <div dangerouslySetInnerHTML={{__html: video.embed.html}}/>
                </div>
            ) : null}
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
            {errorToast ? (
                <div className='toast--error'>
                    {errorToast}
                </div>
            ) : null}
        </div>
    );
};

export default Resources;
