import React, {useContext, useEffect, useState} from 'react';
import PageContent from "../../../components/page/PageContent";
import localization from "../../../localization";
import MessagingActions from "../../../components/messaging/MessagingActions";
import Dropdown from "../../../components/content/Dropdown";
import Messaging from "../../../components/messaging/Messaging";
import {store} from "../../../store";
import api from "../../../api";


const Answers = ({selectedMessage, webinarName, webinarLink, webinarId, eventParticipants, backAction, selectPerson, loggedInUserEventParticipant}) => {
    const [messages, setMessages] = useState([]);
    const [mutedChats, setMutedChats] = useState([]);
    const [chat, setChat] = useState(null);
    const {currentUser, twilioClient, setActiveChat} = useContext(store);
    useEffect(() => {
        setMessages(selectedMessage.childThreads.map((item) => ({
            ...item,
            body: item.message,
            timestamp: item.createdDate,
            user: eventParticipants.filter(({participantUser}) => participantUser.id === item.userId)[0].participantUser,
            isCreator: currentUser.id === item.userId
        })));
    }, []);
    return (
        <PageContent
            title={<div dangerouslySetInnerHTML={{__html: selectedMessage.body.replace(/style="[^"]*"/g, '')}}/>}
            backAction={backAction}
            isFullWidth={true}
        >
            <MessagingActions>
                <Dropdown
                    button={(<img alt='elipses' src='/images/elipses.svg' />)}
                >
                    <button
                        onClick={() => {
                            // mute
                        }}
                    >
                        {mutedChats.indexOf(selectedMessage.chatId) >= 0 ? localization.messagingUnmute : localization.messagingMute}
                    </button>
                </Dropdown>
            </MessagingActions>
            <Messaging
                key={messages.length}
                messages={messages}
                sendMessage={(message) => {
                    api.post(`/api/v1/event/${webinarId}/qna`, {userId: currentUser.id, parentQnaThreadId: selectedMessage.id, message}).then(({data}) => {
                        const updated = [...messages, {
                            ...data,
                            body: data.message,
                            timestamp: data.createdDate,
                            user: currentUser
                        }];
                        setMessages(updated);
                        selectedMessage.childThreads = updated;
                    });
                }}
                hoverActions={(message) => (
                    <>
                        <button
                            onClick={() => {
                                api.delete(`/api/v1/event/${webinarId}/qna/${message.id}`).then(({data}) => {
                                    const updated = messages.filter(({id}) => message.id !== id);
                                    setMessages(updated);
                                    selectedMessage.childThreads = updated;
                                });
                            }}
                            disabled={message.isCreator ? false : (!loggedInUserEventParticipant.host && !loggedInUserEventParticipant.admin)}
                        >
                            {localization.deletE}
                        </button>
                    </>
                )}
                placeholder={localization.webinarAnswersPlaceholder}
                selectPerson={selectPerson}
            />
        </PageContent>
    );
};

export default Answers;
