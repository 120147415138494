import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import Page from "../../components/page/Page";
import Navigation from "../../components/navigation/Navigation";
import NavigationItem from "../../components/navigation/NavigationItem";
import Person from "../../components/content/Person";
import ProfileDetails from "../../components/profile/ProfileDetails";
import {store} from "../../store";
import api from "../../api";
import PageContent from "../../components/page/PageContent";
import Profile from "../../components/profile/Profile";
import {Helmet} from "react-helmet";
import copyToClipboard from "../../utils/copyToClipboard";
import localization from "../../localization";


const Contacts = ({}) => {
    const [selectedPersonId, setSelectedPersonId] = useState(null);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [confirmationToast, setConfirmationToast] = useState(null);
    const [filter, setFilter] = useState(null);
    const {currentUser, favorites, setFavorites} = useContext(store);
    useEffect(() => {
        if (!favorites.length) {
            api.get(`/api/v1/favourite-contact/user/${currentUser.id}`).then(({data}) => {
                setFavorites(data);
            });
        }
    }, []);
    return (
        <Page
            className='page-contacts'
        >
            <Helmet>
                <title>Contacts | {process.env.REACT_APP_PROJECT_NAME}</title>
                <meta name="description" content="All your favorite contacts in place. See their contact, message and video chat with them, keep notes, and stay connected." />
            </Helmet>
            <Navigation
                title={(
                    <div className='flex-between'>
                        <div>{localization.contactsTitle}</div>
                        <div className='flex-between'>
                            {/*<button
                                className='button--search'
                                onClick={() => {
                            }}>
                                <img alt='search' src='/images/search.svg' />
                            </button>*/}
                            {/*<div className='button--filter'>
                                <Dropdown
                                    button={(<img alt='filter' src='/images/filter.svg' />)}
                                >
                                    <button
                                        onClick={() => {
                                            setFilter(null);
                                        }}
                                    >
                                        {localization.contactsAll}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFilter('EVENT');
                                        }}
                                    >
                                        {localization.contactsEvent}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setFilter('DIGITIZED');
                                        }}
                                    >
                                        {localization.contactsDigitized}
                                    </button>
                                </Dropdown>
                            </div>*/}
                            <button
                                className='button--filter'
                                onClick={() => {
                                    copyToClipboard(currentUser.myLastLink);
                                    setConfirmationToast(localization.copiedToClipboard);
                                    setTimeout(() => setConfirmationToast(null), 2000);
                            }}>
                                <img alt='filter' src='/images/share.svg' />
                            </button>
                        </div>
                    </div>
                )}
            >
                <NavigationItem
                    content={(
                        <Person
                            image={currentUser.image}
                            title={`${localization.contactsMyContact} (${currentUser.firstName})`}
                            fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                            description={`${currentUser.position}, ${currentUser.organization}`}
                        />
                    )}
                    onClick={() => {
                        setSelectedPersonId(null);
                        setSelectedProfile(true);
                    }}
                />
                {favorites.filter(({source}) => !filter || filter === source).map((item, index) => (
                    <NavigationItem
                        key={index}
                        content={(
                            <Person
                                image={item.user2.image}
                                title={`${item.user2.firstName} ${item.user2.lastName}`}
                                description={`${item.user2.position}, ${item.user2.organization}`}
                            />
                        )}
                        action={(
                            <button>
                                <img alt='move' src='/images/move.svg' />
                            </button>
                        )}
                        onClick={() => {
                            setSelectedProfile(false);
                            setSelectedPersonId(null);
                            setTimeout(() => setSelectedPersonId(item.user2.id), 0);
                        }}
                        isActive={selectedPersonId === item.user2.id}
                    />
                ))}
            </Navigation>
            {selectedPersonId ? (
                <ProfileDetails
                    personId={selectedPersonId}
                    backAction={() => setSelectedPersonId(null)}
                    noBackOnDesktop
                />
            ) : null}
            {selectedProfile ? (
                <PageContent
                    title={`${currentUser.firstName}${localization.profile}`}
                    backAction={() => setSelectedProfile(false)}
                    noBackOnDesktop
                >
                    <Helmet>
                        <title>{currentUser.firstName} {currentUser.lastName} | {process.env.REACT_APP_PROJECT_NAME}</title>
                        <meta name="description" content='Everything about your account on Last Link' />
                    </Helmet>
                    <Profile
                        user={currentUser}
                    />
                </PageContent>
            ) : null}
            {confirmationToast ? (
                <div className='toast--confirmation'>
                    {confirmationToast}
                </div>
            ) : null}
        </Page>
    );
};

export default withRouter(Contacts);
