import React from 'react';
import classNames from 'classnames';


const options = [{
    label: 'S',
    value: 'SUNDAY'
}, {
    label: 'M',
    value: 'MONDAY'
}, {
    label: 'T',
    value: 'TUESDAY'
}, {
    label: 'W',
    value: 'WEDNESDAY'
}, {
    label: 'T',
    value: 'THURSDAY'
}, {
    label: 'F',
    value: 'FRIDAY'
}, {
    label: 'S',
    value: 'SATURDAY'
}];

const WeekInput = ({value, onChange}) => {
    const array = value ? value.split(',') : [];
    return (
        <div className='input-week'>
            {options.map((item, index) => {
                const isSelected = array.indexOf(item.value) >= 0;
                return (
                    <div
                        className={classNames('input-week--item', {'input-week--item--selected': isSelected})}
                        onClick={() => onChange(
                            (isSelected ? array.filter((v) => v !== item.value) : [...array, item.value]).join(',')
                        )}
                    >
                        <div>{item.label}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default WeekInput;
