import React from 'react';
import classNames from 'classnames';


const Input = ({value, onChange, placeholder, type, min, max, error, disabled, onBlur}) => {
    return (
        <div className={classNames('form-input', {'form-input--error': error})}>
            <input
                onChange={(event) => onChange(event.target.value)}
                onBlur={onBlur}
                value={value}
                placeholder={placeholder}
                type={type || 'text'}
                disabled={disabled}
                min={min}
                max={max}
            />
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default Input;
