import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Input from "../../../components/form/Input";
import TextArea from "../../../components/form/TextArea";
import DatePicker from "../../../components/form/DatePicker";
import ModalRight from "../../../components/modal/ModalRight";
import DataPair from "../../../components/content/DataPair";
import TimePicker from "../../../components/form/TimePicker";
import TimezonePicker from "../../../components/form/TimezonePicker";
import Checkbox from "../../../components/form/Checkbox";
import copyToClipboard from '../../../utils/copyToClipboard';
import api from "../../../api";
import {store} from "../../../store";
import localization from "../../../localization";
import SuffixInput from "../../../components/form/SuffixInput";
import Select from "react-select";
import ModalConfirmation from "../../../components/modal/ModalConfirmation";
import InputNumber from "../../../components/form/InputNumber";
import WeekInput from "../../../components/form/WeekInput";
import ImageUpload from "../../../components/form/ImageUpload";
import RichTextInput from "../../../components/form/RichTextInput";


const currencyOptions = [{
    label: 'USD: United States Dollar',
    value: 'USD'
}, {
    label: 'INR: Indian Rupee',
    value: 'INR'
}, {
    label: 'SAR: Saudi Riyal',
    value: 'SAR'
}];

const frequencyOptions = [{
    label: 'One Time',
    value: 'ONE_TIME'
// }, {
//     label: 'Recurring',
//     value: 'RECURRING'
}];

const repeatOptions = [{
    label: 'Does not repeat',
    value: false
}, {
    label: 'Repeats Every',
    value: true
}];

const repeatTypeOptions = [{
    label: 'Day',
    value: 'DAILY'
}, {
    label: 'Week',
    value: 'WEEKLY'
}, {
    label: 'Month',
    value: 'MONTHLY'
}, {
    label: 'Year',
    value: 'YEARLY'
}];

const CreateWebinar = ({webinar, handleCreate, handleClose, setConfirmationToast}) => {
    const [errors, setErrors] = useState({});
    const [duplicate, setDuplicate] = useState(null);
    const [suffixValid, setSuffixValid] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [sponsors, setSponsors] = useState([]);
    const [customForm, setCustomForm] = useState(null);
    const [customEditForm, setCustomEditForm] = useState(null);
    const [confirmRemove, setConfirmRemove] = useState(null);
    const [zoomAccounts, setZoomAccounts] = useState([]);
    const {currentUser} = useContext(store);
    const [formData, setFormData] = useState(webinar ? ({
        ...webinar,
        isPrivate: webinar.private,
        lastLinkSuffix: webinar.lastLink.split('/w/')[1],
        eventType: webinar.creatorOrganization ? 'ORGANIZATION' : 'INDIVIDUAL',
        expiredDisabled: webinar.eventExpiryDate === null,
        startDisabled: webinar.meetingStartTime === null,
        eventExpiryDate: webinar.eventExpiryDate ? moment(webinar.eventExpiryDate).toDate() : null,
        eventExpiryTime: webinar.eventExpiryDate ? moment(webinar.eventExpiryDate) : null,
        meetingStartDate: webinar.meetingStartTime ? moment(webinar.meetingStartTime).toDate() : null,
        meetingStartTime: webinar.meetingStartTime ? moment(webinar.meetingStartTime) : null,
        durationEnabled: !!webinar.meetingDuration,
        durationHours: webinar.meetingDuration ? parseInt(webinar.meetingDuration / 60) : 0,
        durationMinutes: webinar.meetingDuration ? parseInt(webinar.meetingDuration % 60) : 0,
        callDetailsEnabled: !!webinar.callDetails || !!webinar.meetingUrl,
        openAdvanced: false,
        sponsorsDisabled: !(webinar.eventSponsorsOrganizations || []).length,
        organizationSponsorsIds: (webinar.eventSponsorsOrganizations || []).map(({organization}) => organization.id),
        creatorOrganizationId: webinar.creatorOrganization ? webinar.creatorOrganization.id : null,
        singleClick: !webinar.dynamicFormRequests.length,
        dynamicFormRequests: webinar.dynamicFormRequests,
        eventPrice: webinar.paidEvent ? webinar.eventPrice : null
    }) : ({
        eventType: 'INDIVIDUAL',
        durationHours: 0,
        durationMinutes: 0,
        timeZoneId: currentUser.timeZoneId,
        timeZoneName: currentUser.timeZoneName,
        isPrivate: false,
        callDetailsEnabled: false,
        openAdvanced: false,
        expiredDisabled: true,
        startDisabled: true,
        sponsorsDisabled: true,
        singleClick: true,
        dynamicFormRequests: [],
        paidEvent: false,
        recurring: false,
        resourceUploadAccess: true
    }));
    useEffect(() => {
        api.get(`/api/v1/organization`).then(({data}) => {
            setOrganizations(data);
        });
        api.get(`/api/v1/organization/status/FOLLOWER`).then(({data}) => {
            setSponsors(data.map((item) => ({label: item.name, value: item.id})));
        });
        api.get(`/api/v1/zoom`).then(({data}) => {
            setZoomAccounts(data);
        });
    }, []);

    const renderCustomForm = (form, setter, cancel, submit) => (
        <div className='create-webinar--custom'>
            <Input
                value={form.fieldNameOrQuestion}
                onChange={(value) => setter({...form, fieldNameOrQuestion: value})}
                placeholder={localization.webinarCreateJoinTitle}
            />
            <Input
                value={form.defaultValue}
                onChange={(value) => setter({...form, defaultValue: value})}
                placeholder={localization.webinarCreateJoinPlaceholder}
            />
            <Checkbox
                value={form.required}
                text={localization.webinarCreateJoinRequired}
                onChange={() => setter({...form, required: !form.required})}
            />
            <div>
                <button
                    className='create-webinar--custom--remove'
                    onClick={() => setConfirmRemove(() => cancel)}
                >
                    {localization.webinarCreateJoinRemove}
                </button>
                <button
                    className='create-webinar--custom--done'
                    disabled={!form.fieldNameOrQuestion || !form.defaultValue}
                    onClick={submit}
                >
                    {localization.webinarCreateJoinDone}
                </button>
            </div>
        </div>
    );
    return (
        <ModalRight
            title={webinar ? localization.webinarCreateTitleEdit : localization.webinarCreateTitle}
            handleClose={handleClose}
            action={(
                <button
                    className='submit-button'
                    onClick={() => {
                        const errors = [
                            'name', 'description',
                            ...(formData.expiredDisabled ? [] : ['eventExpiryDate', 'eventExpiryTime']),
                            ...(formData.startDisabled ? [] : ['meetingStartDate', 'meetingStartTime']),
                        ].filter((item) => !formData[item]).reduce((obj, item) => {
                            obj[item] = localization.mandatory;
                            return obj;
                        }, {});
                        if (formData.paidEvent) {
                            ['amount', 'currency', 'frequency'].forEach((item) => {
                                if (!formData.eventPrice[item]) {
                                    errors[item] = localization.mandatory;
                                }
                            })
                        }
                        if (formData.recurring) {
                            ['numberOfOccurrence', 'endsOn'].forEach((item) => {
                                if (!formData.recurringEvent[item]) {
                                    errors[item] = localization.mandatory;
                                }
                            })
                        }
                        setErrors(errors);
                        if (!Object.keys(errors).length && suffixValid) {
                            const data = {
                                ...formData,
                                name: formData.name,
                                description: formData.description,
                                callDetails: formData.callDetails,
                                tags: formData.tags,
                                meetingDuration: formData.durationEnabled ? ((parseInt(formData.durationHours || 0) * 60) + parseInt(formData.durationMinutes || 0)) : 0,
                                lastLinkSuffix: formData.lastLinkSuffix,
                                isPrivate: formData.isPrivate,
                                maxNumberOfParticipants: 1000
                            };
                            if (formData.expiredDisabled) {
                                data.eventExpiryDate = null;
                            } else {
                                data.eventExpiryDate = formData.eventExpiryDate;
                                data.eventExpiryDate.setHours(formData.eventExpiryTime.hour());
                                data.eventExpiryDate.setMinutes(formData.eventExpiryTime.minute());
                            }
                            if (formData.startDisabled) {
                                data.meetingStartTime = null;
                            } else {
                                data.meetingStartTime = formData.meetingStartDate;
                                data.meetingStartTime.setHours(formData.meetingStartTime.hour());
                                data.meetingStartTime.setMinutes(formData.meetingStartTime.minute());
                            }
                            setDuplicate(null);
                            if (webinar) {
                                api.put(`/api/v1/event/${webinar.id}`, data).then(({data}) => {
                                    copyToClipboard(data.lastLink);
                                    handleCreate(data);
                                    handleClose();
                                    setConfirmationToast(localization.webinarCreateCopied);
                                    setTimeout(() => setConfirmationToast(null), 2500);
                                });
                            } else {
                                api.post(`/api/v1/event`, data).then(({data}) => {
                                    copyToClipboard(data.lastLink);
                                    handleCreate(data);
                                    handleClose();
                                    setConfirmationToast(localization.webinarCreateCopied);
                                    setTimeout(() => setConfirmationToast(null), 2500);
                                }).catch((response) => {
                                    const {message} = response.response.data;
                                    if (message === localization.webinarCreateTaken) {
                                        setDuplicate(message);
                                    }
                                });
                            }
                        }
                    }}
                >
                    {webinar ? localization.webinarCreateSave : localization.webinarCreateCreate}
                </button>
            )}
        >
            <div className='create-webinar'>
                <DataPair
                    title={localization.webinarCreateBy}
                    content={(
                        <div className='create-webinar--checkboxes'>
                            <Checkbox
                                value={formData.eventType === 'INDIVIDUAL'}
                                text={(<div>{`${currentUser.firstName} ${currentUser.lastName}`} {!organizations.length ? (<span>(<a href='https://www.loom.com/share/3e40df9c7ef445e98deebe435a657707' target='_blank'>{localization.webinarCreateLearn}</a>)</span>) : null}</div>)}
                                onChange={() => setFormData({...formData, eventType: 'INDIVIDUAL', creatorOrganizationId: null})}
                                disabled={webinar}
                            />
                            {organizations.map((item) => (
                                <Checkbox
                                    value={formData.creatorOrganizationId === item.id}
                                    text={item.name}
                                    onChange={() => setFormData({...formData, eventType: 'ORGANIZATION', creatorOrganizationId: item.id})}
                                    disabled={webinar}
                                />
                            ))}
                        </div>
                    )}
                />
                <div className='separator'/>
                <DataPair
                    title={localization.webinarCreateTopic}
                    content={(
                        <Input
                            value={formData.name}
                            error={!formData.name && errors.name}
                            onChange={(value) => setFormData({...formData, name: value})}
                            placeholder={`${currentUser.firstName}’s Webinar on Networking`}
                        />
                    )}
                />
                <DataPair
                    title={localization.webinarCreateDescribe}
                    content={(
                        <RichTextInput
                            value={formData.description}
                            error={!formData.description && errors.description}
                            onChange={(value) => setFormData({...formData, description: value})}
                            placeholder={localization.webinarCreateDescribePlaceholder}
                        />
                    )}
                />
                <DataPair
                    title={localization.webinarCreateCoverImage}
                    content={(
                        <ImageUpload
                            userId={currentUser.id}
                            image={formData.coverImage}
                            imageType='COVER_IMAGE'
                            onChange={(value) => setFormData({...formData, coverImage: value})}
                        />
                    )}
                />
                <DataPair
                    title={localization.webinarCreateDetails}
                    content={(
                        <div>
                            <div className='create-webinar--checkboxes'>
                                <Checkbox
                                    value={!formData.callDetailsEnabled}
                                    text={localization.webinarCreateCallNo}
                                    onChange={() => setFormData({...formData, callDetailsEnabled: false, meetingUrl: null, callDetails: null})}
                                />
                                <Checkbox
                                    value={formData.callDetailsEnabled}
                                    text={localization.webinarCreateCallYes}
                                    onChange={() => setFormData({...formData, callDetailsEnabled: true})}
                                />
                            </div>
                            {formData.callDetailsEnabled ? (
                                <div>
                                    {/*<div className='flex-center'>
                                        <Checkbox
                                            value={formData.meetingProviderSelected}
                                            text={localization.webinarCreateZoomAutomatically}
                                            disabled={!zoomAccounts.length}
                                            onChange={() => setFormData({...formData, meetingUrl: null, callDetails: null, meetingProviderSelected: {meetingProviderType: 'ZOOM', email: zoomAccounts[0].email}})}
                                        />
                                        <div>&nbsp;</div>
                                        <div>
                                            ({zoomAccounts.length ? `${localization.webinarCreateZoomUsed} ${zoomAccounts[0].email}` : localization.webinarCreateZoomConnect})
                                        </div>
                                    </div>
                                    {!zoomAccounts.length ? (
                                        <button
                                            className='blue-button'
                                            onClick={() => {
                                                const clientId = 'O7Ibt4c8Qay_Qm0BfWq5Ug';
                                                const redirectUrl = `${window.location.origin}/zoom-signup`;
                                                window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`);
                                            }}
                                        >
                                            {localization.webinarCreateZoomSignIn}
                                        </button>
                                    ) : null}
                                    <Checkbox
                                        value={!formData.meetingProviderSelected}
                                        text={localization.webinarCreateZoomManually}
                                        onChange={() => setFormData({...formData, meetingProviderSelected: null})}
                                    />*/}
                                    {!formData.meetingProviderSelected ? (
                                        <div>
                                            <Input
                                                value={formData.meetingUrl}
                                                onChange={(value) => setFormData({...formData, meetingUrl: value})}
                                                placeholder={localization.webinarCreateCallUrl}
                                            />
                                            <br/>
                                            <TextArea
                                                value={formData.callDetails}
                                                onChange={(value) => setFormData({...formData, callDetails: value})}
                                                placeholder={localization.webinarCreateCallDetails}
                                                rows={6}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    )}
                />
                <div className='separator'/>
                <DataPair
                    title={localization.webinarCreateLastLink}
                    content={(
                        <SuffixInput
                            value={formData.lastLinkSuffix}
                            error={duplicate}
                            onChange={(value) => setFormData({...formData, lastLinkSuffix: value})}
                            prefix={`${window.location.origin.split('//')[1]}/w/`}
                            placeholder='1d341a3d1ea54fbd85e2496eceaa6c7e'
                            getUrl={(value) => `/api/v1/event/lastlink/${value}/exists`}
                            validate={(isValid) => setSuffixValid(isValid)}
                        />
                    )}
                />
                <DataPair
                    title={localization.webinarCreateSecurity}
                    content={(
                        <>
                            <Checkbox
                                value={!formData.isPrivate}
                                text={localization.webinarCreateSecurityPublic}
                                onChange={(value) => setFormData({...formData, isPrivate: false})}
                            />
                            <Checkbox
                                value={formData.isPrivate}
                                text={localization.webinarCreateSecurityPrivate}
                                onChange={(value) => setFormData({...formData, isPrivate: true})}
                            />
                        </>
                    )}
                />
                <div className='separator'/>
                <DataPair
                    title={(
                        <div
                            className={classNames('create-webinar--advanced', {'create-webinar--advanced--opened': formData.openAdvanced})}
                            onClick={() => setFormData({...formData, openAdvanced: !formData.openAdvanced})}
                        >
                            {localization.webinarCreateAdvanced}
                            <img alt='arrow' src='/images/arrow-down.svg'/>
                        </div>
                    )}
                />
                {formData.openAdvanced ? (
                    <div>
                        {/*<DataPair
                            title={localization.webinarCreateExpire}
                            content={(
                                <div>
                                    <div className='create-webinar--checkboxes'>
                                        <Checkbox
                                            value={formData.expiredDisabled}
                                            text={localization.webinarCreateExpireNo}
                                            onChange={() => setFormData({...formData, expiredDisabled: true, eventExpiryDate: null, eventExpiryTime: null})}
                                        />
                                        <Checkbox
                                            value={!formData.expiredDisabled}
                                            text={localization.webinarCreateExpireYes}
                                            onChange={() => setFormData({...formData, expiredDisabled: false})}
                                        />
                                    </div>
                                    {!formData.expiredDisabled ? (
                                        <div className={classNames('create-webinar--datetime')}>
                                            <div>
                                                <DatePicker
                                                    value={formData.eventExpiryDate}
                                                    error={!formData.eventExpiryDate && errors.eventExpiryDate}
                                                    onChange={(value) => setFormData({...formData, eventExpiryDate: value})}
                                                    minDate={new Date()}
                                                    placeholder={localization.webinarCreateExpireDate}
                                                />
                                                <img alt='logo' src='/images/expand.svg'/>
                                            </div>
                                            <div>
                                                <TimePicker
                                                    value={formData.eventExpiryTime}
                                                    error={!formData.eventExpiryTime && errors.eventExpiryTime}
                                                    onChange={(value) => setFormData({...formData, eventExpiryTime: value})}
                                                    placeholder={localization.webinarCreateExpireTime}
                                                />
                                                <img alt='logo' src='/images/expand.svg'/>
                                            </div>
                                            <div>
                                                <TimezonePicker
                                                    value={formData.timeZoneName}
                                                    error={!formData.timeZoneName && errors.timeZoneName}
                                                    onChange={(value) => setFormData({...formData, ...value})}
                                                />
                                                <img alt='logo' src='/images/expand.svg'/>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        />*/}
                        <DataPair
                            title={localization.webinarCreateStart}
                            content={(
                                <div>
                                    <div className='create-webinar--checkboxes'>
                                        <Checkbox
                                            value={formData.startDisabled}
                                            text={localization.webinarCreateStartNo}
                                            onChange={() => setFormData({...formData, startDisabled: true, meetingStartDate: null, meetingStartTime: null, durationHours : 0, durationMinutes: 0})}
                                        />
                                        <Checkbox
                                            value={!formData.startDisabled}
                                            text={localization.webinarCreateStartYes}
                                            onChange={() => setFormData({...formData, startDisabled: false, meetingStartDate: new Date(), meetingStartTime: moment(new Date())})}
                                        />
                                    </div>
                                    {!formData.startDisabled ? (
                                        <div>
                                            <div className={classNames('create-webinar--datetime')}>
                                                <div>
                                                    <DatePicker
                                                        value={formData.meetingStartDate}
                                                        error={!formData.meetingStartDate && errors.meetingStartDate}
                                                        onChange={(value) => setFormData({...formData, meetingStartDate: value})}
                                                        minDate={new Date()}
                                                        placeholder={localization.webinarCreateStartDate}
                                                    />
                                                    <img alt='logo' src='/images/expand.svg'/>
                                                </div>
                                                <div>
                                                    <TimePicker
                                                        value={formData.meetingStartTime}
                                                        error={!formData.meetingStartTime && errors.meetingStartTime}
                                                        onChange={(value) => setFormData({...formData, meetingStartTime: value})}
                                                        placeholder={localization.webinarCreateStartTime}
                                                    />
                                                    <img alt='logo' src='/images/expand.svg'/>
                                                </div>
                                                <div>
                                                    <TimezonePicker
                                                        value={formData.timeZoneName}
                                                        error={!formData.timeZoneName && errors.timeZoneName}
                                                        onChange={(value) => setFormData({...formData, ...value})}
                                                    />
                                                    <img alt='logo' src='/images/expand.svg'/>
                                                </div>
                                            </div>
                                            <div className='create-webinar--recurring'>
                                                <div className='create-webinar--select'>
                                                    <Select
                                                        value={repeatOptions.filter(({value}) => formData.recurring === value)[0]}
                                                        onChange={({value}) => setFormData({...formData, recurring: value, recurringEvent: value ? ({numberOfOccurrence: 1, recurringType: 'DAILY'}) : null})}
                                                        options={repeatOptions}
                                                    />
                                                    <img alt='logo' src='/images/expand.svg'/>
                                                </div>
                                                {formData.recurring ? (
                                                    <>
                                                        <InputNumber
                                                            value={formData.recurringEvent.numberOfOccurrence}
                                                            onChange={(value) => setFormData({...formData, recurringEvent: {...formData.recurringEvent, numberOfOccurrence: value}})}
                                                            error={!formData.recurringEvent.numberOfOccurrence && errors.numberOfOccurrence}
                                                        />
                                                        <div className='create-webinar--select'>
                                                            <Select
                                                                value={repeatTypeOptions.filter(({value}) => formData.recurringEvent.recurringType === value)[0]}
                                                                onChange={({value}) => setFormData({...formData, recurringEvent: {...formData.recurringEvent, recurringType: value, repeatOn: null}})}
                                                                options={repeatTypeOptions}
                                                            />
                                                            <img alt='logo' src='/images/expand.svg'/>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                            {formData.recurring && formData.recurringEvent.recurringType === 'WEEKLY' ? (
                                                <div className='create-webinar--subblock'>
                                                    <div>{localization.webinarCreateRecurringRepeatOn}</div>
                                                    <WeekInput
                                                        value={formData.recurringEvent.repeatOn}
                                                        onChange={(value) => setFormData({...formData, recurringEvent: {...formData.recurringEvent, repeatOn: value}})}
                                                    />
                                                </div>
                                            ) : null}
                                            {formData.recurring && formData.recurringEvent.recurringType === 'MONTHLY' ? (
                                                <div className='create-webinar--subblock'>
                                                    <div>{localization.webinarCreateRecurringRepeatOn}</div>
                                                    {(() => {
                                                        const options = [{
                                                            label: `${localization.webinarCreateRecurringMonthly} ${moment(formData.meetingStartDate).date()}th`,
                                                            value: null
                                                        }, {
                                                            label: `${localization.webinarCreateRecurringMonthly} ${localization[`order${moment(formData.meetingStartDate).week() - moment(formData.meetingStartDate).startOf('month').week() + 1}`]} ${localization[`day${moment(formData.meetingStartDate).day() + 1}`]}`,
                                                            value: 'occurrence'
                                                        }];
                                                        return (
                                                            <div className='create-webinar--select'>
                                                                <Select
                                                                    value={options.filter(({value}) => formData.recurringEvent.repeatOn === value)[0]}
                                                                    onChange={({value}) => setFormData({...formData, recurringEvent: {...formData.recurringEvent, repeatOn: value}})}
                                                                    options={options}
                                                                />
                                                                <img alt='logo' src='/images/expand.svg'/>
                                                            </div>
                                                        );
                                                    })()}
                                                </div>
                                            ) : null}
                                            {formData.recurring ? (
                                                <div className='create-webinar--subblock'>
                                                    <div>{localization.webinarCreateRecurringEndsOn}</div>
                                                    <div className={classNames('create-webinar--datetime')}>
                                                        <div>
                                                            <DatePicker
                                                                value={moment(formData.recurringEvent.endsOn).toDate()}
                                                                error={!formData.recurringEvent.endsOn && errors.endsOn}
                                                                onChange={(value) => setFormData({...formData, recurringEvent: {...formData.recurringEvent, endsOn: value}})}
                                                                placeholder={localization.webinarCreateRecurringEndsOn}
                                                                minDate={new Date()}
                                                            />
                                                            <img alt='logo' src='/images/expand.svg'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        />
                        {!formData.startDisabled ? (
                            <Checkbox
                                value={formData.durationEnabled}
                                text={localization.webinarCreateDuration}
                                onChange={(value) => setFormData({...formData, durationEnabled: value, durationHours: value ? formData.durationHours : 0, durationMinutes: value ? formData.durationMinutes : 0})}
                                disabled={formData.startDisabled}
                            />
                        ) : null}
                        {!formData.startDisabled && formData.durationEnabled ? (
                            <div className='create-webinar--duration'>
                                <div>
                                    <Input
                                        value={formData.durationHours}
                                        onChange={(value) => setFormData({...formData, durationHours: value})}
                                        type='number'
                                        min={0}
                                    />
                                    <div className='create-webinar--duration--text'>{localization.webinarCreateHours}</div>
                                </div>
                                <div>
                                    <Input
                                        value={formData.durationMinutes}
                                        onChange={(value) => setFormData({...formData, durationMinutes: value})}
                                        type='number'
                                        min={0}
                                        max={59}
                                    />
                                    <div className='create-webinar--duration--text'>{localization.webinarCreateMinutes}</div>
                                </div>
                            </div>
                        ) : null}
                        <DataPair
                            title={localization.webinarCreateSponsors}
                            content={(
                                <div>
                                    <div className='create-webinar--checkboxes'>
                                        <Checkbox
                                            value={formData.sponsorsDisabled}
                                            text={localization.webinarCreateSponsorsNo}
                                            onChange={() => setFormData({...formData, sponsorsDisabled: true, organizationSponsorsIds: []})}
                                        />
                                        <Checkbox
                                            value={!formData.sponsorsDisabled}
                                            text={localization.webinarCreateSponsorsYes}
                                            onChange={() => setFormData({...formData, sponsorsDisabled: false})}
                                        />
                                    </div>
                                    {!formData.sponsorsDisabled ? (
                                        <div className='create-webinar--select'>
                                            <Select
                                                value={(formData.organizationSponsorsIds || []).map((item) => sponsors.filter(({value}) => item === value)[0])}
                                                onChange={(data) => setFormData({...formData, organizationSponsorsIds: (data || []).map(({value}) => value)})}
                                                placeholder={localization.webinarCreateSponsorsPlaceholder}
                                                options={sponsors}
                                                isMulti
                                            />
                                            <img alt='logo' src='/images/expand.svg'/>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        />
                        <DataPair
                            title={localization.webinarCreateTags}
                            content={(
                                <Input
                                    value={formData.tags}
                                    onChange={(value) => setFormData({...formData, tags: value})}
                                    placeholder={localization.webinarCreateTagsPlaceholder}
                                />
                            )}
                        />
                        <DataPair
                            title={localization.webinarCreateJoinRegistration}
                            content={(
                                <div>
                                    <div className='create-webinar--checkboxes'>
                                        <Checkbox
                                            value={formData.singleClick}
                                            text={localization.webinarCreateJoinSingle}
                                            onChange={() => setFormData({...formData, singleClick: true, organizationSponsorsIds: []})}
                                        />
                                        <Checkbox
                                            value={!formData.singleClick}
                                            text={localization.webinarCreateJoinCustom}
                                            onChange={() => setFormData({...formData, singleClick: false})}
                                        />
                                    </div>
                                    {!formData.singleClick ? (
                                        <div>
                                            <div className='create-webinar--description'>
                                                <div>{localization.webinarCreateJoinDescription}</div>
                                            </div>
                                            {formData.dynamicFormRequests.map((item, index) => (
                                                customEditForm && customEditForm.index === index ? renderCustomForm(
                                                    customEditForm,
                                                    setCustomEditForm,
                                                    () => {
                                                        setFormData({...formData, dynamicFormRequests: [...formData.dynamicFormRequests.filter((item, index) => customEditForm.index !== index)]});
                                                        setCustomEditForm(null);
                                                    },
                                                    () => {
                                                        setFormData({...formData, dynamicFormRequests: [...formData.dynamicFormRequests.map((item, index) => customEditForm.index === index ? customEditForm : item)]});
                                                        setCustomEditForm(null);
                                                    }
                                                ) : (
                                                    <div
                                                        key={index}
                                                        className='create-webinar--custom'
                                                        onClick={() => {
                                                            if (!customForm && !customEditForm) {
                                                                setCustomEditForm({
                                                                    ...item,
                                                                    index
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <DataPair
                                                            title={`${item.fieldNameOrQuestion}${item.required ? '*' : ''}`}
                                                            content={(
                                                                <Input
                                                                    onChange={(value) => {}}
                                                                    placeholder={item.defaultValue}
                                                                    disabled
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )
                                            ))}
                                            {!customForm ? (
                                                <div className='create-webinar--custom'>
                                                    <button
                                                        className='create-webinar--custom--create'
                                                        onClick={() => {
                                                            if (!customEditForm) {
                                                                setCustomForm({required: false});
                                                            }
                                                        }}
                                                    >
                                                        {localization.webinarCreateJoinCreate}
                                                    </button>
                                                </div>
                                            ) : renderCustomForm(
                                                customForm,
                                                setCustomForm,
                                                () => {
                                                    setCustomForm(null)
                                                },
                                                () => {
                                                    setFormData({...formData, dynamicFormRequests: [...formData.dynamicFormRequests, customForm]});
                                                    setCustomForm(null);
                                                }
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        />
                        <DataPair
                            title={localization.webinarCreateAccess}
                            content={(
                                <div>
                                    <div className='create-webinar--checkboxes'>
                                        <Checkbox
                                            value={!formData.paidEvent}
                                            text={localization.webinarCreateAccessFree}
                                            onChange={() => setFormData({...formData, paidEvent: false, eventPrice: null})}
                                        />
                                        <Checkbox
                                            value={formData.paidEvent}
                                            text={localization.webinarCreateAccessPaid}
                                            onChange={() => setFormData({...formData, paidEvent: true, eventPrice: {}})}
                                        />
                                    </div>
                                    {formData.paidEvent ? (
                                        <div className='create-webinar--access'>
                                            <InputNumber
                                                value={formData.eventPrice.amount}
                                                onChange={(value) => setFormData({...formData, eventPrice: {...formData.eventPrice, amount: value}})}
                                                placeholder={localization.webinarCreateAccessEntry}
                                                error={!formData.eventPrice.amount && errors.amount}
                                            />
                                            <div className={classNames('create-webinar--select', {'create-webinar--select--error': !formData.eventPrice.currency && errors.currency})}>
                                                <Select
                                                    value={currencyOptions.filter(({value}) => formData.eventPrice.currency === value)[0]}
                                                    onChange={({value}) => setFormData({...formData, eventPrice: {...formData.eventPrice, currency: value}})}
                                                    placeholder={localization.webinarCreateAccessCurrency}
                                                    options={currencyOptions}
                                                />
                                                <img alt='logo' src='/images/expand.svg'/>
                                                {!formData.eventPrice.currency && errors.currency ? (
                                                    <div className='create-webinar--select--message'>{errors.currency}</div>
                                                ) : null}
                                            </div>
                                            <div className={classNames('create-webinar--select', {'create-webinar--select--error': !formData.eventPrice.frequency && errors.frequency})}>
                                                <Select
                                                    value={frequencyOptions.filter(({value}) => formData.eventPrice.frequency === value)[0]}
                                                    onChange={({value}) => setFormData({...formData, eventPrice: {...formData.eventPrice, frequency: value}})}
                                                    placeholder={localization.webinarCreateAccessFrequency}
                                                    options={frequencyOptions}
                                                />
                                                <img alt='logo' src='/images/expand.svg'/>
                                                {!formData.eventPrice.frequency && errors.frequency ? (
                                                    <div className='create-webinar--select--message'>{errors.frequency}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        />
                        <DataPair
                            title={localization.webinarCreateResources}
                            content={(
                                <div className='create-webinar--checkboxes'>
                                    <Checkbox
                                        value={formData.resourceUploadAccess}
                                        text={localization.webinarCreateResourcesHost}
                                        onChange={() => setFormData({...formData, resourceUploadAccess: true})}
                                    />
                                    <Checkbox
                                        value={!formData.resourceUploadAccess}
                                        text={localization.webinarCreateResourcesAll}
                                        onChange={() => setFormData({...formData, resourceUploadAccess: false})}
                                    />
                                </div>
                            )}
                        />
                    </div>
                ) : null}
            </div>
            {confirmRemove ? (
                <ModalConfirmation
                    title={localization.removefieldTitle}
                    description={localization.removefieldDescription}
                    confirm={localization.removefieldOk}
                    cancel={localization.removefieldCancel}
                    onCancel={() => setConfirmRemove(null)}
                    onConfirm={() => confirmRemove()}
                />
            ) : null}
        </ModalRight>
    );
};

export default CreateWebinar;