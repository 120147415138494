import React from 'react';
import classNames from 'classnames';


const DataPair = ({title, content}) => {
    return (
        <div className='data-pair'>
            {title ? (
                <div className='data-pair--title'>
                    {title}
                </div>
            ) : null}
            {content ? (
                <div className='data-pair--content'>
                    {content}
                </div>
            ) : null}
        </div>
    );
};

export default DataPair;
