export const linkTypes = [
    'LINKEDIN',
    'TWITTER',
    'INSTAGRAM',
    'FACEBOOK',
    'MOBILE_NUMBER',
    'EMAIL',
    'SKYPE',
    'WEBSITE',
    'BLOG',
    'SNAPCHAT',
    'MEDIUM',
    'PINTEREST',
    'WECHAT',
    'WHATSAPP',
    'YOUTUBE',
    'CALENDLY',
    'RESEARCH_GATE',
    'ANGEL_LIST',
    'DESK_NUMBER',
];

export const linkPrefixes = [
    '',
    'https://twitter.com/',
    'https://www.instagram.com/',
    'https://www.facebook.com/',
    '',
    '',
    '',
    '',
    '',
    'https://www.snapchat.com/add/',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
];