import React, {useContext, useEffect, useState} from 'react';
import Person from "../../../components/content/Person";
import {store} from "../../../store";
import api from "../../../api";
import localization from "../../../localization";
import Dropdown from "../../../components/content/Dropdown";


const OrganizationMembers = ({organization, selectPerson}) => {
    const [members, setMembers] = useState([]);
    const {currentUser} = useContext(store);
    useEffect(() => {
        api.get(`/api/v1/organization/${organization.id}/users`).then(({data}) => {
            setMembers(data);
        });
    }, []);
    return (
        <div className='people'>
            {members.map((item, index) => (
                <div
                    key={index}
                    className='people--item'
                >
                    <Person
                        image={item.user.image}
                        title={`${item.user.firstName} ${item.user.lastName}`}
                        description={item.user.organization ? `${item.user.position ? `${item.user.position} at ` : ''}${item.user.organization}` : null}
                        subtitle={item.owner ? localization.owner : (item.admin ? localization.admin : (item.member ? localization.member: (item.follower ? localization.follower : null)))}
                        selectPerson={() => selectPerson(item.user.id)}
                    />
                    {organization.loggedInUserOrganizationUserView.owner && item.user.id !== currentUser.id ? (
                        <button className='people--hover'>
                            <Dropdown
                                button={(<img alt='elipses' src='/images/elipses.svg' />)}
                            >
                                <button
                                    onClick={() => {
                                        api.post(
                                            `/api/v1/organization/${organization.id}/users`,
                                            {...item, admin: !item.admin}
                                        ).then(({data}) => {
                                            setMembers(members.map((member) => (member.id === data.id) ? data : member))
                                        });
                                    }}
                                >
                                    {item.admin ? localization.accountOrganizationAdminRemove : localization.accountOrganizationAdmin}
                                </button>
                                <button
                                    onClick={() => {
                                    }}
                                >
                                    {localization.accountOrganizationRemove}
                                </button>
                            </Dropdown>
                        </button>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default OrganizationMembers;
