import React, {useContext, useEffect, useState} from 'react';
import Person from "../../../components/content/Person";
import {store} from "../../../store";
import api from "../../../api";
import Note from "./Note";
import FavoritesButton from "../../../components/form/FavoritesButton";
import localization from "../../../localization";
import Dropdown from "../../../components/content/Dropdown";
import SearchInput from "../../../components/form/SearchInput";
import InfiniteScroll from "../../../components/content/InfiniteScroll";


const People = ({data, selectPerson, setDefaultTab}) => {
    const [createNote, setCreateNote] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({pageNo: 0, totalElements: 0});
    const {currentUser, favorites, setFavorites, eventNotifications, setEventNotifications} = useContext(store);
    useEffect(() => {
        const notifications = eventNotifications.filter(({objectId, notificationsType}) => objectId === data.id && notificationsType === 'EVENT_PEOPLE');
        if (notifications.length) {
            api.put(`/api/v1/notifications/read`, {notificationsType: 'EVENT_PEOPLE', objectId: data.id}).then(({data}) => {
                api.get(`/api/v1/notifications/unread`).then(({data}) => {
                    setEventNotifications(data);
                });
            });
        }
    }, []);
    useEffect(() => {
        if (!favorites.length) {
            api.get(`/api/v1/favourite-contact/user/${currentUser.id}`).then(({data}) => {
                setFavorites(data);
            });
        }
        api.get(`/api/v2/event/${data.id}/participants?query=&pageNo=0&pageSize=25`).then(({data}) => {
            setPage({pageNo: data.pageNo, totalElements: data.totalElements});
            setParticipants(data.content.filter(({status}) => status === 'APPROVED'));
        });
    }, []);
    return (
        <div className='people'>
            <SearchInput
                value={search}
                onChange={(value) => {
                    setSearch(value);
                    if (!value.length || value.length > 2) {
                        api.get(`/api/v2/event/${data.id}/participants?query=${value}&pageNo=0&pageSize=25`).then(({data}) => {
                            setPage({pageNo: data.pageNo, totalElements: data.totalElements});
                            setParticipants(data.content.filter(({status}) => status === 'APPROVED'));
                        });
                    }
                }}
                placeholder={localization.webinarPeopleSearch}
            />
            <div className='people--header'>
                {localization.formatString(localization.webinarPeopleShowing, page.totalElements)}
            </div>
            {participants.map((item, index) => (
                <div
                    key={index}
                    className='people--item'
                >
                    <Person
                        image={item.participantUser.image}
                        title={`${item.participantUser.firstName} ${item.participantUser.lastName}`}
                        description={item.participantUser.organization ? `${item.participantUser.position ? `${item.participantUser.position} at ` : ''}${item.participantUser.organization}` : null}
                        content={currentUser.id === item.participantUser.id ? (
                            <a onClick={() => setCreateNote(item)}>
                                {item.notes || localization.webinarPeopleAddNote}
                            </a>
                        ) : (
                            <div>{item.notes}</div>
                        )}
                        selectPerson={() => selectPerson(item.participantUser.id)}
                    />
                    <div className='people--actions'>
                        {item.participantUser.linkedInProfileLink ? (
                            <a
                                href={item.participantUser.linkedInProfileLink}
                                target='_blank'
                            >
                                <img alt='linkedin' src='/images/linkedin.svg' />
                            </a>
                        ) : null}
                        <FavoritesButton
                            person={item.participantUser}
                            preventAutoLoad
                            justImage
                        />
                        <button
                            onClick={() => {
                                setDefaultTab(1);
                                selectPerson(item.participantUser.id);
                            }}
                        >
                            <img alt='chat' src='/images/chat.svg' />
                        </button>
                    </div>
                </div>
            ))}
            {createNote ? (
                <Note
                    eventId={data.id}
                    eventParticipant={createNote}
                    handleUpdate={() => {
                        api.get(`/api/v1/event/${data.id}/participants`).then(({data}) => {
                            setParticipants(data.filter(({status}) => status === 'APPROVED'));
                        })
                    }}
                    handleClose={() => setCreateNote(null)}
                />
            ) : null}
            <br/>
            <InfiniteScroll
                callback={() => {
                    if (page.totalElements > ((page.pageNo + 1) * 25)) {
                        api.get(`/api/v2/event/${data.id}/participants?query=${search}&pageNo=${page.pageNo + 1}&pageSize=25`).then(({data}) => {
                            setPage({pageNo: data.pageNo, totalElements: data.totalElements});
                            setParticipants([...participants, ...data.content.filter(({status}) => status === 'APPROVED')]);
                        });
                    }
                }}
            />
        </div>
    );
};

export default People;
