import React, {useEffect, useRef} from 'react';

let scrollTime = 0;

const InfiniteScroll = ({callback}) => {
    const scroller = useRef();

    const listener = () => {
        if (((scroller.current.getBoundingClientRect().y - 100) < window.innerHeight) && (scrollTime + 1000 < new Date().getTime())) {
            scrollTime = new Date().getTime();
            callback();
        }
    };

    useEffect(() => {
        document.getElementById('pageBody').addEventListener("scroll", listener);
        return () => {
            document.getElementById('pageBody').removeEventListener("scroll", listener);
        };
    });
    return (
        <div ref={scroller} />
    );
};

export default InfiniteScroll;
