import React, {useEffect} from 'react';



const ZoomSignUp = () => {
    useEffect(() => {
        window.opener.location.href = `${window.opener.location.origin}/zoom-login/${window.location.search}`;
        window.close();
    }, []);
    return (
        <div/>
    );
};

export default ZoomSignUp;