import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import api from "../../api";
import {store} from "../../store";
import localization from "../../localization";
import ModalConfirmation from "../modal/ModalConfirmation";


const FavoritesButton = ({person, preventAutoLoad, justImage}) => {
    const {currentUser, favorites, setFavorites} = useContext(store);
    const [unfavorite, setUnfavorite] = useState(false);
    const isFavorite = favorites.filter(({user2}) => user2.id === person.id).length;
    useEffect(() => {
        if (currentUser && !preventAutoLoad && !favorites.length) {
            api.get(`/api/v1/favourite-contact/user/${currentUser.id}`).then(({data}) => {
                setFavorites(data);
            });
        }
    }, []);
    return currentUser && currentUser.id !== person.id ? (
        <>
            <button
                className={classNames({'bordered-button': !justImage})}
                onClick={() => {
                    if (isFavorite) {
                        setUnfavorite(true);
                    } else {
                        const data = {
                            userId1: currentUser.id,
                            userId2: person.id,
                            favourite: true
                        };
                        api.post(`/api/v1/favourite-contact`, data).then(({data}) => {
                            setFavorites([...favorites, data]);
                        });
                    }
                }}
            >
                {isFavorite ? (
                    <img alt='star' src='/images/star-active.svg' />
                ) : (
                    <img alt='star' src='/images/star.svg' />
                )}
                {!justImage ? (
                    isFavorite ? (
                        <div>{localization.favoriteFavorited}</div>
                    ) : (
                        <div>{localization.favoriteAdd}</div>
                    )
                ) : null}
            </button>
            {unfavorite ? (
                <ModalConfirmation
                    title={localization.unfavoriteTitle}
                    description={localization.formatString(localization.unfavoriteDescription, person.firstName, person.lastName)}
                    confirm={localization.unfavoriteOk}
                    cancel={localization.unfavoriteCancel}
                    onCancel={() => setUnfavorite(false)}
                    onConfirm={() => {
                        const data = {
                            userId1: currentUser.id,
                            userId2: person.id,
                            favourite: false
                        };
                        api.put(`/api/v1/favourite-contact`, data).then(({data}) => {
                            setFavorites(favorites.filter(({user2}) => user2.id !== person.id));
                            setUnfavorite(false);
                        });
                    }}
                />
            ) : null}
        </>
    ) : null;
};

export default FavoritesButton;
