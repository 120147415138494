import React, {useContext, useEffect, useRef, useState} from "react";
import ModalCentral from "../../../components/modal/ModalCentral";
import localization from "../../../localization";
import InputPair from "../../../components/form/InputPair";
import Dropdown from "../../../components/content/Dropdown";
import api from "../../../api";
import {store} from "../../../store";


const ResourcesFolder = ({handleClose, setConfirmationToast, onSubmit, webinarId, folder}) => {
    const [formData, setFormData] = useState(folder);
    return (
        <ModalCentral
            title={folder.id ? localization.webinarResourcesCreateFolderEdit : localization.webinarResourcesCreateFolderTitle}
            submitLabel={folder.id ? localization.save : localization.create}
            handleClose={handleClose}
            onSubmit={() => {
                if (formData.name) {
                    api[folder.id ? 'put' : 'post'](`/api/v1/event/${webinarId}/resource/category${folder.id ? `/${folder.id}` : ''}`, formData).then(({data}) => {
                        setConfirmationToast(`${formData.name} ${localization.webinarResourcesVideoSuccess}`);
                        setTimeout(() => setConfirmationToast(null), 3000);
                        onSubmit(folder.id ? formData : data);
                        setFormData({name: ''});
                        handleClose();
                    });
                }
            }}
            disabled={!formData.name}
        >
            <InputPair
                label={localization.webinarResourcesCreateFolder}
                value={formData.name}
                onChange={(value) => setFormData({...formData, name: value})}
                required
            />
        </ModalCentral>
    )
};

export default ResourcesFolder;