import React from "react";

export default {
    webinarLinks: 'Last Links',
    webinarHosted: 'Hosted by',
    webinarAttended: 'Attended by',
    me: 'me',
    webinarCreateLL: '+ Create',
    webinarSearch: 'Search Last Links',
    webinarManage: 'Manage',
    webinarAbout: 'About',
    webinarPeople: 'People',
    webinarDiscussion: 'Chat',
    webinarQnA: 'Q&A',
    webinarResources: 'Resources',
    webinarRegistrants: 'Registrants',
    webinarNotApprovedTilte: 'You are registered successfully. Since this is a private Last Link, the host needs to approve your registration.',
    webinarNotApprovedDescription: 'You will be notified on approval. Hang tight.',
    webinarDeniedTitle: 'You are registered successfully. But the host denied your registration.',
    webinarDeniedDescription: 'We will notify you if this changes.',
    webinarRemovedTitle: 'You no longer have access. The host has removed you from this Last Link.',
    webinarRemovedDescription: 'We will notify you if this changes.',
    webinarGridGoodMorning: 'Good Morning',
    webinarGridSearch: 'Search Last Links',
    webinarGridCreate: '+ Create Last Link',
    webinarGridShare: 'Share',
    webinarAboutLLURL: 'Last Link URL',
    webinarAboutLive: 'Live',
    webinarAboutJoin: 'Join Call',
    webinarAboutMore: 'More Call information',
    webinarAboutCallDetails: 'Schedule & Details',
    webinarAboutSpreadWord: 'Spread the word',
    webinarAboutCopyLink: 'Copy link',
    webinarAboutHostedBy: 'Hosted by',
    webinarAboutAboutWebinar: 'Description',
    webinarAboutPresented: 'Presented by',
    webinarAboutTags: 'Tags',
    webinarAboutHost: 'Host',
    webinarAboutAt: 'at',
    webinarAnswersPlaceholder: 'Share your answer or thoughts about this question...',
    webinarResourcesSearch: 'Search Resource',
    webinarResourcesAdd: '+ Add New',
    webinarResourcesFile: 'File',
    webinarResourcesFolder: 'Folder',
    webinarResourcesShowing: 'Showing {0} resources',
    webinarResourcesCreateTitle: 'New Resource',
    webinarResourcesCreateEdit: 'Edit Resource',
    webinarResourcesCreateFolderTitle: 'New Folder',
    webinarResourcesCreateFolderEdit: 'Edit Folder',
    webinarResourcesCreateFolder: 'Folder Name',
    webinarResourcesCreateName: 'Name this resource',
    webinarResourcesCreateUrl: 'Input Url or Attach resource',
    webinarResourcesCreateLink: 'Type URL here or attach below',
    webinarResourcesCreateAttach: '+ Attach',
    webinarResourcesCreateAttachDescription: 'Attach pdf or video. Max upload size is 100MB.',
    webinarResourcesCreateAttachError: 'Max upload size is 100MB.',
    webinarResourcesCreateSearchableTags: 'Add Searchable Tags',
    webinarResourcesCreateSearchableTagsPlaceholder: 'Ex: Marketing, Recording, etc..',
    webinarResourcesEdit: 'Edit Resource',
    webinarResourcesDelete: 'Delete Resource',
    webinarResourcesDeleteTitle: 'Are you sure to delete this resource?',
    webinarResourcesDeleteDescription: 'This cannot be undone. Once you delete this resource, everyone will lose access to it.',
    webinarResourcesDeleteOk: 'Yes, Delete',
    webinarResourcesDeleteCancel: 'No, Keep',
    webinarResourcesDeleteConfirmation: 'has been deleted',
    webinarResourcesVideo: 'Upload Video',
    webinarResourcesPdf: 'Upload a pdf file',
    webinarResourcesWord: 'Upload a word file',
    webinarResourcesVideoLoaded: 'File attached for upload',
    webinarResourcesVideoSuccess: 'was successfully uploaded',
    webinarResourcesVideoFail: 'Upload failed. Please try again',
    webinarResourcesTableName: 'Name',
    webinarResourcesTableUploaded: 'By',
    webinarResourcesTableUpload: 'Date',
    webinarResourcesTableRelated: 'Tags',
    webinarResourcesTableAction: 'Action',
    webinarResourcesMe: 'me',
    webinarResourcesEmptyTitle: 'No Resources yet',
    webinarResourcesEmptyDescription: 'Here you’ll be able to add resources such as videos, presentations, files, URLs and more to display to the attendees.',
    webinarResourcesEmptyAction: 'Start Adding',
    webinarQnAFilter: 'Filter',
    webinarQnAUpwote: 'By Upvote',
    webinarQnAPlaceholder: 'Write a post or question...',
    webinarPeopleAddNote: 'Why should others meet with you? Add a Note.',
    webinarPeopleSearch: 'Search people',
    webinarPeopleShowing: 'Showing {0} people',
    webinarNotePlaceholder: 'Type here...',
    webinarRegistrantsSearch: 'Search by name',
    webinarRegistrantsCSV: 'Export as CSV',
    webinarRegistrantsShowing: 'Showing {0} registrants',
    webinarRegistrantsTableName: 'Name',
    webinarRegistrantsTableEmail: 'Email Address',
    webinarRegistrantsTableStatus: 'Status',
    webinarRegistrantsTableRegistration: 'Registration Date',
    webinarRegistrantsTableTicket: 'Ticket',
    webinarRegistrantsTableActions: 'Actions',
    webinarRegistrantsApprove: 'Approve',
    webinarRegistrantsDeny: 'Deny',
    webinarRegistrantsRemove: 'Remove',
    webinarRegistrantsRemoveConfirm: 'Yes, Remove',
    webinarRegistrantsRemoveQuestion: 'Are you sure you want to remove {0} {1}? This will revoke the user’s access to this Last Link. The user can rejoin at any time.',
    webinarRegistrantsBlock: 'Block',
    webinarRegistrantsBlockConfirm: 'Yes, Block',
    webinarRegistrantsBlockQuestion: 'Are you sure you want to block {0} {1}? This will revoke the user’s access to this Last Link. We won’t let them know you blocked them.',
    webinarRegistrantsEmptyTitle: 'No Attendees yet',
    webinarRegistrantsEmptyDescription: 'Here you’ll be able to see all the registrants, their email, approval status, and download all other details displayed.',
    webinarRegistrantsEmptyAction: 'Start Inviting',
    webinarCreateTitle: 'Create a Last Link',
    webinarCreateTitleEdit: 'Edit a Last Link',
    webinarCreateCopied: 'Last Link created and copied',
    webinarCreateTaken: 'LastLink URL already taken. Please use another lastLink.',
    webinarCreateBy: 'Created By',
    webinarCreateTopic: 'Topic',
    webinarCreateAdmins: 'Assign Admins',
    webinarCreateLearn: 'Learn to host as organization',
    webinarCreateDescribe: 'Description',
    webinarCreateCoverImage: 'Attach Cover Image',
    webinarCreateDescribePlaceholder: 'Description',
    webinarCreateCallNo: 'No Call',
    webinarCreateCallYes: 'Add Call',
    webinarCreateCallUrl: 'Call Link URL (optional)',
    webinarCreateCallDetails: 'More call details',
    webinarCreateAdvanced: 'Advanced options',
    webinarCreateSecurity: 'Security',
    webinarCreateSecurityPublic: 'Public (Anyone with the link can join and be added immediately. No host approval required.)',
    webinarCreateSecurityPrivate: 'Private (Anyone with the link can join, but will not receive immediate access. Host approval required.',
    webinarCreateExpire: 'Last Link Expiry',
    webinarCreateExpireNo: 'Does not expire',
    webinarCreateExpireYes: 'Expires On',
    webinarCreateExpireDate: 'Expiry Date',
    webinarCreateExpireTime: 'Expiry Time',
    webinarCreateLastLink: 'Last Link URL',
    webinarCreateSponsors: 'Sponsors',
    webinarCreateAccess: 'Access',
    webinarCreateAccessFree: 'Free',
    webinarCreateAccessPaid: 'Paid',
    webinarCreateAccessCurrency: 'Currency',
    webinarCreateAccessFrequency: 'Frequency',
    webinarCreateAccessEntry: 'Entry Price',
    webinarCreateResources: 'Who can upload resources',
    webinarCreateResourcesHost: 'Host & Admin Only',
    webinarCreateResourcesAll: 'All participants',
    webinarCreateSponsorsNo: 'No Sponsors',
    webinarCreateSponsorsYes: 'Add Sponsors',
    webinarCreateSponsorsPlaceholder: 'Choose sponsors that you Follow',
    webinarCreateStart: 'Start Time (for call)',
    webinarCreateStartNo: 'Does not have a start time',
    webinarCreateStartYes: 'Add a time',
    webinarCreateStartDate: 'Start Date',
    webinarCreateStartTime: 'Start Time',
    webinarCreateStartNone: 'This event does not have a start time',
    webinarCreateDuration: 'Add Duration for this event',
    webinarCreateHours: 'Hours',
    webinarCreateMinutes: 'Minutes',
    webinarCreateDetails: 'Call Details',
    webinarCreateTags: 'Tags',
    webinarCreateRecurringRepeatOn: 'Repeat on',
    webinarCreateRecurringEndsOn: 'Ends on',
    webinarCreateRecurringMonthly: 'Monthly on the',
    webinarCreateJoinRegistration: 'Joining/Registration',
    webinarCreateJoinSingle: 'Single-Click Join',
    webinarCreateJoinCustom: 'Add Custom Fields',
    webinarCreateJoinDescription: 'Registrant already provides First Name, Last Name and Email when registering with Single-Click Join. Add fields below to know other relevant information about your registrant.',
    webinarCreateJoinCreate: 'Add New Field',
    webinarCreateJoinTitle: 'Field Title (Ex: Phone Number)',
    webinarCreateJoinPlaceholder: 'Input Placeholder (Ex: +1-1231338463)',
    webinarCreateJoinRequired: 'Required Field',
    webinarCreateJoinRemove: 'Remove',
    webinarCreateJoinDone: 'Done',
    webinarCreateZoomAutomatically: 'Create Zoom Call automatically',
    webinarCreateZoomConnect: 'Connect your Zoom account to create this',
    webinarCreateZoomUsed: 'Zoom account used:',
    webinarCreateZoomManually: 'Enter Call Details Manually',
    webinarCreateZoomSignIn: 'Connect Zoom Account',
    webinarJoinRegistrationTitle: 'Registration Complete. Fill this information before you get in',
    webinarJoinRegistrationDescription: 'Only host can view this',
    webinarJoinRegistrationSubmit: 'Let me in',
    webinarCreateSave: 'Save Changes & Copy URL',
    webinarCreateCreate: 'Create & Copy URL',
    webinarDefaultTitle: 'Quickly Create a Last Link',
    webinarDefaultIsPrivate: 'This Last Link is private. And it never expires.',
    webinarDefaultAddDetails: 'Add details now',
    webinarDefaultOrLater: 'or later',
    webinarCreateTagsPlaceholder: 'Ex: Marketing, Technology (Separate by commas)',
    onboardingWelcome: 'Hi,',
    onboardingAlmostThere: 'Almost there. Let’s tidy up your profile and you’ll be all set!',
    onboardingFillIn: 'Fill in these mandatory fields for the best networking experience...',
    onboardingCompany: 'Company/Organization Name',
    onboardingPosition: 'Your Title/Position here',
    onboardingLocation: 'Location',
    onboardingLinkedin: 'LinkedIn Profile URL',
    onboardingLinkedinGo: 'Go to my Linkedin Profile',
    preonboardingHi: 'Hi, who’s this?',
    preonboardingLetsPut: 'Let’s put a face and a name so people can recognize you.',
    preonboardingProfileImage: 'Profile Image',
    preonboardingChooseImage: 'Choose Image',
    preonboardingFirstName: 'First Name',
    preonboardingLastName: 'Last Name',
    joinSignIn: 'Sign in',
    joinDescription: 'Description',
    joinRegister: 'Sign In to Register',
    joinPresented: 'Presented by',
    joinHosted: 'Hosted by',
    landingCheckCheck: 'Check your inbox',
    landingCheckEmailed: 'We have emailed a magic link to',
    landingCheckClick: 'Click the button in the email to sign in',
    landingCheckOk: 'Ok',
    landingLinkExpiredTitle: 'Link expired',
    landingLinkExpiredDescription: 'The sign-in link has expired. Please request a new sign-in link by logging-in.',
    landingWelcome: 'Welcome',
    landingLogBack: 'Sign in to access your account.',
    landingLoginLinkedin: 'Sign In with Linkedin',
    landingLoginZoom: 'Sign in with Zoom',
    landingLoginEmail: 'Continue with email',
    landingLoginEmailPlaceholder: 'example@example.com',
    landingLoginEmailLabel: 'Email',
    landingByClicking: 'By clicking the button above, you accept Last Link’s',
    landingTerms: 'Terms of Service',
    landingPrivacy: 'Privacy Policy',
    joinExpired: 'Registration is not open',
    joinJoin: 'Register as',
    joinFree: 'Free',
    joinJoinLinkedin: 'Sign In with Linkedin',
    joinJoinZoom: 'Sign in with Zoom',
    joinJoinEmail: 'Continue with email',
    joinJoinEmailPlaceholder: 'Email Address',
    messagesTitle: 'Messages',
    contactsTitle: 'Contacts',
    contactsMyContact: 'My Contact',
    contactsAll: 'All',
    contactsEvent: 'Event',
    contactsDigitized: 'Digitized',
    profile: '\'s Profile',
    save: 'Save',
    create: 'Create',
    cancel: 'Cancel',
    deletE: 'Delete',
    mandatory: 'This field is mandatory',
    copiedToClipboard: 'Copied to clipboard',
    accountTitle: 'Account',
    accountOrganizations: 'Organizations',
    accountOrganizationsCreate: 'Create Organization',
    accountOrganizationsEdit: 'Edit Organization',
    accountOrganizationsNone: 'None created or joined.',
    accountOrganizationsLearn: 'Learn how to',
    accountOrganizationsCreateNew: 'Create New',
    accountOrganizationsName: 'Name',
    accountOrganizationsAbout: 'Write about the organization...',
    accountOrganizationCreated: 'Organization created',
    accountOrganizationAdmin: 'Make as Admin',
    accountOrganizationAdminRemove: 'Remove as Admin',
    accountOrganizationRemove: 'Remove Member',
    accountOrganizationFollow: 'Follow',
    accountOrganizationFollowing: 'Following',
    accountOrganizationInviteTitle: 'Invite Members to your organization',
    accountOrganizationInviteDescription: 'Share the invitation link below to invite someone to join this organization. Please do not share the invite link with members outside, unless you would like them to have access to this organization.',
    accountEdit: 'Edit Profile',
    accountLogoutTile: 'Logout?',
    accountLogoutDescription: 'Are you sure you would want to logout? You will not be notified of any activity once you logout.',
    accountLogoutYes: 'Yes, Logout',
    accountLogoutNo: 'No, Stay Logged-in',
    profileFirstName: 'First Name',
    profileLastName: 'Last Name',
    profileWriteAbout: 'Write about',
    profileYourself: 'yourself',
    profileCompany: 'Company/Organization',
    profileLink: 'Profile Link',
    profileInfo: 'Info',
    profileOrganization: 'Organization',
    profilePosition: 'Tile/Position',
    profileUploadLogo: 'Upload Company Logo',
    profileContacts: 'Contacts / Links',
    profileContactsDescription: 'Contact links listed here are visible to members of any Last Link that you join.',
    unfavoriteTitle: 'Remove Favorite Contact',
    unfavoriteDescription: 'Are you sure you want to remove this favorited contact {0} {1}? Once removed, this cannot be undone.',
    unfavoriteOk: 'Unfavorite Contact',
    unfavoriteCancel: 'Keep Contact',
    removefieldTitle: 'Remove Custom Field',
    removefieldDescription: 'Are you sure you want to remove this field? Once removed, this cannot be undone.',
    removefieldOk: 'Remove Field',
    removefieldCancel: 'Keep Field',
    url: 'URL',
    handle: 'Handle',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    username: 'username',
    descNumber: 'Desk Number',
    links: 'Links',
    about: 'About',
    edit: 'Edit',
    copy: 'Copy',
    tabAbout: 'About',
    tabChat: 'Chat',
    tabMembers: 'Members',
    tabInviteMembers: 'Invite Members',
    navigationLinks: 'Links',
    navigationContacts: 'Contacts',
    navigationMessages: 'Messages',
    navigationAccount: 'Account',
    notificationsTurnOn: 'Turn on',
    notificationsDesktop: 'Desktop notifications?',
    noCancel: 'No, Cancel',
    send: 'Send',
    messagingBlock: 'Block User',
    messagingMute: 'Mute Notifications',
    messagingUnmute: 'Unmute Notifications',
    favoriteFavorited: 'Favorited',
    favoriteAdd: 'Add to Favorites',
    yourLocation: 'Your location',
    available: 'Available',
    notAvailable: 'Not Available',
    notValid: 'Your input is not valid. Please change',
    owner: 'Owner',
    admin: 'Admin',
    follower: 'Follower',
    member: 'Member',
    order1: 'first',
    order2: 'second',
    order3: 'third',
    order4: 'fourth',
    order5: 'fifth',
    order6: 'sixth',
    day1: 'Sunday',
    day2: 'Monday',
    day3: 'Tuesday',
    day4: 'Wednesday',
    day5: 'Thursday',
    day6: 'Friday',
    day7: 'Saturday',
}