import React from 'react';
import classNames from 'classnames';

const ModalRight = ({title, children, action, handleClose}) => {
    return (
        <div className={classNames('modal-right')}>
            <div className='modal-right--background'/>
            <div className='modal-right--dialog'>
                <div className='modal-right--title'>
                    {title}
                    <button onClick={handleClose}>×</button>
                </div>
                <div className='modal-right--content'>
                    {children}
                </div>
                <div className='modal-right--action'>
                    {action}
                </div>
            </div>
        </div>
    );
};

export default ModalRight;