import React from 'react';
import classNames from 'classnames';
import Badge from "../content/Badge";

const NavigationItem = ({image, title, description, content, action, badge, className, onClick, isActive, isAction, isSmaller, isWithoutBorder, isImageBordered}) => {
    return (
        <div
            className={classNames('navigation-item', {'navigation-item--active': isActive, 'navigation-item--smaller': isSmaller, 'navigation-item--without-border': isWithoutBorder}, className)}
            onClick={onClick}
        >
            {image ? (
                <div className={classNames('navigation-item--image', {'navigation-item--image--bordered': isImageBordered})}>
                    {image}
                </div>
            ) : null}
            <div className={classNames('navigation-item--content', {'navigation-item--content--action': isAction})}>
                {title ? (
                    <div className='navigation-item--title'>
                        {title}
                    </div>
                ) : null}
                {description ? (
                    <div className='navigation-item--description'>
                        {description}
                    </div>
                ) : null}
                {content}
            </div>
            {action ? (
                <div className='navigation-item--action'>
                    {action}
                </div>
            ) : null}
            <Badge count={badge}/>
        </div>
    );
};

export default NavigationItem;