import React, {useState} from 'react';
import classNames from 'classnames';
import toBase64 from "../../utils/toBase64";
import api from "../../api";


const LogoUpload = ({value, onChange, placeholder, type, error, userId}) => {
    const [uploadedImage, setUploadedImage] = useState(null);
    return (
        <div className={classNames('form-input logo-upload', {'form-input--error': error})}>
            {(uploadedImage || value) ? (
                <div className='logo-upload--image'>
                    <img alt='logo' src={uploadedImage || value}/>
                </div>
            ) : null}
            <input
                onChange={() => {}}
                placeholder={(uploadedImage || value) ? null : placeholder}
                type={type || 'text'}
            />
            <div className='logo-upload--button'>
                <button>Upload</button>
                <input
                    type='file'
                    accept='image/png,image/jpg,image/jpeg'
                    onChange={(event) =>{
                        const file = event.target.files[0];
                        if (file) {
                            toBase64(file).then((base64) => {
                                const data = {
                                    userId,
                                    imageType: 'LOGO',
                                    fileName: file.name,
                                    base64: base64.split('base64,')[1]
                                };
                                api.post(`/api/v1/files`, data).then(({data}) => {
                                    setUploadedImage(data.presignedUrl);
                                    onChange(data.presignedUrl);
                                });
                            });
                        }
                    }}
                />
            </div>
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default LogoUpload;
