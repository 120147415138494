import React from 'react';
import classNames from 'classnames';


const Checkbox = ({value, onChange, text, disabled}) => {
    return (
        <div
            className={classNames('checkbox', {'checkbox--disabled': disabled})}
            onClick={() => !disabled && onChange(!value)}
        >
            {value ? (
                <img alt='check' src={'/images/checkbox-active.svg'} />
            ) : (
                <img alt='check' src={'/images/checkbox.svg'} />
            )}
            <div>{text}</div>
        </div>
    );
};

export default Checkbox;
