import React, {Fragment, useEffect, useState} from 'react';
import Block from "../content/Block";
import Person from "../content/Person";
import {linkTypes} from '../../utils/constants';
import Avatar from "../content/Avatar";
import localization from "../../localization";
import api from "../../api";
import Organizaton from "./Organizaton";
import PageContent from "../page/PageContent";
import Tabs from "../tabs/Tabs";



const OrganizatonView = ({organizationId, backAction}) => {
    const [organization, setOrganization] = useState(null);
    useEffect(() => {
        api.get(`/api/v1/organization/${organizationId}`).then(({data}) => {
            setOrganization(data);
        });
    }, []);
    return organization ? (
        <div className='page-account'>
            <PageContent
                title={organization.name}
                backAction={backAction}
            >
                <Organizaton
                    organization={organization}
                />
            </PageContent>
        </div>
    ) : null;
};

export default OrganizatonView;