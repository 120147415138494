import React, {useState} from 'react';
import classNames from 'classnames';
import Input from "./Input";
import api from "../../api";
import localization from "../../localization";


const SuffixInput = ({value, error, disabled, onChange, prefix, placeholder, getUrl, initialValue, validate}) => {
    const [status, setStatus] = useState(null);
    const [valid, setValid] = useState(false);
    return (
        <div className='suffix-input'>
            <div>{prefix}</div>
            <Input
                value={value}
                error={error}
                disabled={disabled}
                onChange={(value) => {
                    const trimmedValue = value.trim().replace(/[+\-/*,.~=#? ]/g,'');
                    onChange(trimmedValue);
                    if (trimmedValue !== initialValue) {
                        api.get(getUrl(trimmedValue), {noLoader: true}).then(({data}) => {
                            setStatus(data.exists ? localization.notAvailable : localization.available);
                            setValid(!data.exists);
                            validate(!data.exists)
                        });
                    } else {
                        setStatus(null);
                    }
                }}
                placeholder={placeholder}
            />
            {status ? (
                <div className={classNames('suffix-input--status', {'suffix-input--status--error': !valid})}>{status}</div>
            ) : null}
        </div>
    );
};

export default SuffixInput;
