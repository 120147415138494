import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';


const Dropdown = ({button, children, className}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdown = useRef();
    useEffect(() => {
        const listener = event => {
            if (!dropdown.current || dropdown.current.contains(event.target)) {
                return;
            }
            setIsOpen(false);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, []);
    return (
        <div
            ref={dropdown}
            className={classNames('dropdown', className)}
        >
            <button onClick={() => setIsOpen(true)}>
                {button}
            </button>
            {isOpen ? (
                <div
                    className='dropdown--body'
                    onClick={() => setIsOpen(false)}
                >
                    {children}
                </div>
            ) : null}
        </div>
    );
};

export default Dropdown;
