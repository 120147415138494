import React from 'react';
import classNames from 'classnames';


const Loader = ({isShowing}) => {
    return (
        <div className={classNames('loader', {'opened': isShowing})}>
                <span className="loader-square">
                    <span className="loader-square-inner">&nbsp;</span>
                </span>
        </div>
    );
};

export default Loader;
