import React, {useState} from 'react';
import classNames from 'classnames';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';


const DatePicker = ({value, onChange, error, placeholder}) => {
    return (
        <div className={classNames('form-input', {'form-input--error': error})}>
            <TimePicker
                showSecond={false}
                value={value}
                onChange={onChange}
                use12Hours
                inputReadOnly
                placeholder={placeholder}
                format='h:mm A'
            />
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default DatePicker;
