import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import {LinkedIn} from "react-linkedin-login-oauth2";
import api from "../../api";
import Avatar from "../../components/content/Avatar";
import {Helmet} from "react-helmet";
import localization from "../../localization";
import moment from "moment";
import momentTimezone from "moment-timezone";
import queryString from "query-string";
import {store} from "../../store";
import Person from "../../components/content/Person";
import timezones from "timezones.json";
import Block from "../../components/content/Block";
import Input from "../../components/form/Input";
import Checkbox from "../../components/form/Checkbox";
import {addHttpsToLink, createLink} from "../../utils/linkUtil";
import {Link} from "react-router-dom";


const Join = ({location, history, onJoinToWebinar}) => {
    const [webinar, setWebinar] = useState(null);
    const [expired, setExpired] = useState(false);
    const [formData, setFormData] = useState({});
    const {currentUser, setCurrentUser} = useContext(store);
    useEffect(() => {
        const webinarLink = location.pathname.split('/w/')[1];
        api.get(`/api/v1/event/public/link/${webinarLink}`).then(({data}) => {
            setWebinar(data);
            if (data.eventExpiryDate && new Date(data.eventExpiryDate).getTime() < new Date().getTime()) {
                setExpired(true);
            }
            const {code} = queryString.parse(location.search);
            if (code) {
                api.get(`/api/v1/users/email-link-signin?code=${code}`, {
                    headers: {
                        'issigninrequest': 'yes',
                    }
                }).then((response) => {
                    localStorage.setItem('joinWebinar', data.id);
                    const {accessToken, user} = response.data;
                    localStorage.setItem('accessToken', accessToken);
                    setCurrentUser(user);
                    if (!user.firstName || !user.lastName) {
                        history.push('/pre-onboarding');
                    } else if (!user.position || !user.organization || !user.timeZoneId) {
                        history.push('/onboarding');
                    } else {
                        history.push('/w');
                    }
                }).catch((response) => {
                    if (response.response.data.message === 'Link is expired!') {
                        history.push('/email-expired');
                    } else {
                        localStorage.setItem('joinWebinar', data.id);
                        history.push('/w');
                    }
                });
            }
        });
    }, []);
    const startTime = webinar && webinar.meetingStartTime ? momentTimezone(webinar.meetingStartTime).tz(currentUser ? currentUser.timeZoneId : webinar.timeZoneId).toDate().getTime() : null;
    const endTime = webinar && webinar.meetingStartTime && webinar.meetingDuration ? (startTime + (webinar.meetingDuration * 60000)) : null;
    return (
        <div className='join'>
            <div className='join--header'>
                <div>
                    <img alt='logo' src={`/images/${process.env.REACT_APP_PROJECT}-logo-big.svg`} />
                </div>
            </div>
            {webinar ? (
                <div className='join--content'>
                    <Helmet>
                        <title>{webinar.name} | {process.env.REACT_APP_PROJECT_NAME}</title>
                        <meta property="og:title" content={`${webinar.name} | ${process.env.REACT_APP_PROJECT_NAME}`} />
                        <meta name="description" content={webinar.description} />
                        <meta name="og:description" content={webinar.description} />
                    </Helmet>
                    <div className='join--content--title'>
                        {webinar.name}
                    </div>
                    <div className='join--content--time'>
                        {startTime ? (
                            <img alt='calendar' src='/images/calendar.svg'/>
                        ) : null}
                        {startTime ? (
                            <div>{moment(startTime).format('MMMM DD, YYYY, hh:mm A ')}</div>
                        ) : null}
                        {endTime ? (
                            <div>&nbsp;- {moment(endTime).format('hh:mm A')}</div>
                        ) : null}
                        {startTime ? (
                            <div>&nbsp;{(timezones.filter(({utc}) => utc.indexOf(currentUser ? currentUser.timeZoneId : webinar.timeZoneId) >= 0)[0] || {}).value}</div>
                        ) : null}
                    </div>
                    <div className='join--content--body'>
                        <div className='join--content--left'>
                            <div className='join--content--image'>
                                <img alt='join-background' src={webinar.coverImage} />
                            </div>
                            <Block
                                title={localization.joinDescription}
                                isNoPadding
                            >
                                {(webinar.description || '').split(/\r?\n/).map((item, index) => (<div key={index}>{item ? (<div dangerouslySetInnerHTML={{__html: addHttpsToLink(createLink(item))}}/>) : (<br />)}</div>))}
                            </Block>
                            {webinar.eventSponsorsOrganizations && webinar.eventSponsorsOrganizations.length ? (
                                <Block
                                    title={localization.joinPresented}
                                    isNoPadding
                                >
                                    <div className='join-content--presented'>
                                        {webinar.eventSponsorsOrganizations.map((item) => (
                                            <div>
                                                <Avatar
                                                    image={item.organization.logo}
                                                    fullName={item.organization.name}
                                                />
                                                <div>{item.organization.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </Block>
                            ) : null}
                        </div>
                        <div className='join--content--right'>
                            <Block
                                title={localization.joinRegister}
                            >
                                {expired ? (
                                    <button
                                        className='submit-button'
                                        onClick={() => {}}
                                        disabled
                                    >
                                        {localization.joinExpired}
                                    </button>
                                ) : (
                                    onJoinToWebinar ? (
                                        <button
                                            className='submit-button'
                                            onClick={() => onJoinToWebinar(webinar.id)}
                                        >
                                            {localization.joinJoin} {currentUser.firstName}
                                        </button>
                                    ) : (
                                        <div>
                                            <div className='join--free'>
                                                <Checkbox
                                                    value={true}
                                                    onChange={() => {}}
                                                />
                                                <div>{localization.joinFree}</div>
                                            </div>
                                            <Input
                                                value={formData.email}
                                                onChange={(value) => setFormData({...formData, email: value})}
                                                placeholder={localization.joinJoinEmailPlaceholder}
                                            />
                                            <button
                                                className='submit-button'
                                                onClick={() => {
                                                    if (formData.email || /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(formData.email) || formData.email.length < 150) {
                                                        localStorage.setItem('joinWebinar', webinar.id);
                                                        api.post(
                                                            `/api/v1/users/email-signin`,
                                                            {email: formData.email, eventId: webinar.id},
                                                            {headers: {'issigninrequest': 'yes'}}
                                                        ).then(({}) => {
                                                            localStorage.setItem('email', formData.email);
                                                            history.push('/check-inbox');
                                                        });
                                                    }
                                                }}
                                                disabled={!formData.email || formData.email.length > 150}
                                            >
                                                {localization.joinJoinEmail}
                                            </button>
                                            <div className='join--separator'/>
                                            <LinkedIn
                                                clientId="861ongbwecsr8p"
                                                redirectUri={`${window.location.origin}/linkedin-signup`}
                                                scope='r_liteprofile,r_emailaddress'
                                                onSuccess={() => {}}
                                                onFailure={() => {}}
                                                renderElement={({onClick, disabled}) => (
                                                    <button
                                                        className='join--linkedin'
                                                        onClick={() => {
                                                            localStorage.setItem('joinWebinar', webinar.id);
                                                            onClick();
                                                        }}
                                                        disabled={disabled}
                                                    >
                                                        <img alt='linkedin' src='/images/linkedin.svg' />
                                                        <div>{localization.joinJoinLinkedin}</div>
                                                    </button>
                                                )}
                                            />
                                            {/*<button
                                                className='join--zoom'
                                                onClick={() => {
                                                    localStorage.setItem('joinWebinar', webinar.id);
                                                    const clientId = 'O7Ibt4c8Qay_Qm0BfWq5Ug';
                                                    const redirectUrl = `${window.location.origin}/zoom-signup`;
                                                    window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`);
                                                }}
                                            >
                                                <img alt='mail' src='/images/zoom.svg' />
                                                <div>{localization.joinJoinZoom}</div>
                                            </button>*/}
                                        </div>
                                    )
                                )}
                            </Block>
                            <br/>
                            <Block
                                title={localization.joinHosted}
                            >
                                {webinar.creatorOrganization ? (
                                    <Person
                                        image={webinar.creatorOrganization.logo}
                                        title={webinar.creatorOrganization.name}
                                        selectPerson={() => {}}
                                    />
                                ) : (
                                    <Person
                                        image={webinar.creator.image}
                                        title={`${webinar.creator.firstName} ${webinar.creator.lastName}`}
                                        description={webinar.creator.organization ? `${webinar.creator.position ? `${webinar.creator.position} ${localization.webinarAboutAt} ` : ''}${webinar.creator.organization}` : null}
                                        selectPerson={() => {}}
                                    />
                                )}
                            </Block>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default withRouter(Join);
