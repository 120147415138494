import React, {useContext, useEffect, useState} from 'react';
import ProfileDetails from "../../components/profile/ProfileDetails";
import {withRouter} from "react-router";
import {store} from "../../store";
import PageContent from "../../components/page/PageContent";
import {Helmet} from "react-helmet";
import Profile from "../../components/profile/Profile";
import api from "../../api";
import queryString from "query-string";



const UserProfile = ({location, history}) => {
    const [suffix, setSuffix] = useState(null);
    const [user, setUser] = useState(null);
    const {currentUser} = useContext(store);
    useEffect(() => {
        const id = localStorage.getItem('profileSuffix') || location.pathname.split('/')[1];
        setSuffix(id);
        if (!currentUser) {
            api.get(`/api/v1/users/last-link/${id}`).then(({data}) => {
                setUser(data);
            });
        }
    }, []);
    return suffix ? (
        currentUser ? (
            <ProfileDetails
                suffix={suffix}
                backAction={() => {
                    localStorage.removeItem('profileSuffix');
                    history.push('/w');
                }}
                defaultTab={queryString.parse(location.search).tab === 'chat' ? 1 : 0}
            />
        ) : (
            user ? (
                <div
                    className='profile-details--unauthorized'
                    onClick={() => {
                        localStorage.setItem('profileSuffix', suffix);
                        history.push('/');
                    }}
                >
                    <PageContent
                        title={`${user.firstName} ${user.lastName}`}
                        className='profile-details'
                    >
                        <Helmet>
                            <title>{user.firstName} {user.lastName} | {process.env.REACT_APP_PROJECT_NAME}</title>
                            <meta name="description" content={`${user.position} | ${user.organization}`} />
                        </Helmet>
                        <Profile
                            user={user}
                        />
                    </PageContent>
                </div>
            ) : null
        )
    ) : null;
};

export default withRouter(UserProfile);