import React, {useState} from 'react';
import classNames from 'classnames';
import timezones from 'timezones.json';
import 'rc-time-picker/assets/index.css';
import Select from "react-select";


const TimezonePicker = ({value, onChange, error}) => {
    const options = timezones.map(({value, utc, text}) => ({label: text, value, id: utc[0]}));
    return (
        <div className={classNames('form-input', {'form-input--error': error})}>
            <Select
                value={options.filter((item) => item.value === value)[0]}
                onChange={(data) => onChange({
                    timeZoneId: data.id,
                    timeZoneName: data.value
                })}
                placeholder=' '
                options={options}
            />
            {error ? (
                <div className='form-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default TimezonePicker;
