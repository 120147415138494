import React from 'react';


const Avatar = ({className, image, fullName, onClick}) => {
    const secondaryImage = `https://ui-avatars.com/api/?name=${fullName}`;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img
                alt='avatar'
                src={image || secondaryImage}
                onError={(event) => {
                    event.target.onerror = null;
                    event.target.src = secondaryImage;
                }}
            />
        </div>
    );
};

export default Avatar;
