import React from 'react';
import classNames from 'classnames';
import localization from "../../localization";

const ModalCentral = ({title, children, submitLabel, disabled, handleClose, onSubmit}) => {
    return (
        <div className={classNames('modal-central')}>
            <div className='modal-central--background'/>
            <div className='modal-central--dialog'>
                <div className='modal-central--title'>
                    {title}
                    <button onClick={handleClose}>×</button>
                </div>
                <div className='modal-central--content'>
                    {children}
                </div>
                <div className='modal-central--actions'>
                    <button
                        className='bordered-button'
                        onClick={handleClose}
                    >
                        {localization.cancel}
                    </button>
                    <button
                        className='action-button'
                        onClick={() => onSubmit()}
                        disabled={disabled}
                    >
                        {submitLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModalCentral;