import React from 'react';
import Input from './Input';



const InputNumber = ({value, onChange, placeholder, error}) => {
    return (
        <div className='input-number'>
            <Input
                value={value}
                onChange={(value) => onChange(value)}
                placeholder={placeholder}
                error={error}
                type='number'
            />
            <button
                onClick={() => onChange((value || 0) + 1)}
            >
                <img alt='logo' src='/images/expand.svg'/>
            </button>
            <button
                onClick={() => onChange(Math.max((value || 0) - 1, 0))}
            >
                <img alt='logo' src='/images/expand.svg'/>
            </button>
        </div>
    );
};

export default InputNumber;
