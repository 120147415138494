import React, {useContext, useState} from 'react';
import Input from "../../../components/form/Input";
import {store} from "../../../store";
import localization from "../../../localization";
import api from "../../../api";
import copyToClipboard from "../../../utils/copyToClipboard";
import SuffixInput from "../../../components/form/SuffixInput";
import NavigationGroup from "../../../components/navigation/NavigationGroup";
import NavigationItem from "../../../components/navigation/NavigationItem";
import Navigation from "../../../components/navigation/Navigation";
import SearchInput from "../../../components/form/SearchInput";
import moment from "moment";



const GridView = ({search, setSearch, eventsCreatedByMe, eventsAttendedByMe, openedGroup, setOpenedGroup, setCreateWebinar, setGridView, setConfirmationToast, selectWebinar}) => {
    const {currentUser} = useContext(store);
    const renderNavigationItem = (item, index) => (
        <div
            key={index}
            className='grid-view--item'
            onClick={() => selectWebinar(item)}
        >
            <div className='grid-view--item--image'>
                <img alt='webinar-background' src={item.coverImage} />
            </div>
            <div className='grid-view--item--content'>
                <div className='grid-view--item--title'>
                    {item.name}
                </div>
                <div className='grid-view--item--subtitle'>
                    By {item.slimUser.firstName} {item.slimUser.lastName}
                </div>
                <div className='grid-view--item--separator'/>
                {item.meetingStartTime ? (
                    <div className='grid-view--item--date'>
                        <img alt='calendar-gray' src='./images/calendar-gray.svg' />
                        {moment(item.meetingStartTime).format('MMM DD, hh:mm A')}
                        {item.meetingDuration ? (
                            <span>&nbsp;- {moment(new Date(item.meetingStartTime).getTime() + (item.meetingDuration * 60000)).format('hh:mm A')}</span>
                        ) : null}
                    </div>
                ) : null}
                <div className='grid-view--item--actions'>
                    <button
                        onClick={() => {
                            copyToClipboard(item.lastLink);
                            setConfirmationToast(localization.copiedToClipboard);
                            setTimeout(() => setConfirmationToast(null), 2000);
                        }}
                    >
                        {localization.webinarGridShare}
                    </button>
                </div>
            </div>
        </div>
    );
    return (
        <div className='grid-view'>
            <div className='grid-view--header'>
                {localization.webinarGridGoodMorning}, {currentUser.firstName}
            </div>
            <div className='grid-view--subheader'>
                <SearchInput
                    value={search}
                    onChange={(value) => setSearch(value)}
                    placeholder={localization.webinarGridSearch}
                />
                <div className='flex-between'>
                    <button
                        className='page-webinars--toggle'
                        onClick={() => setGridView(false)}
                    >
                        <img alt='grid' src='/images/list-view.svg' />
                    </button>
                    <button
                        className='action-button'
                        onClick={() => setCreateWebinar(true)}
                    >
                        {localization.webinarGridCreate}
                    </button>
                </div>
            </div>
            <NavigationGroup
                title={`${localization.webinarHosted} ${localization.me}`}
                collapsable
                opened={openedGroup === 'created'}
                setOpened={() => setOpenedGroup(openedGroup === 'created' ? null : 'created')}
            >
                {eventsCreatedByMe.filter((({eventType, name}) => eventType === 'INDIVIDUAL' && (name || '').indexOf(search) >= 0)).length ? (
                    eventsCreatedByMe.filter((({eventType, name}) => eventType === 'INDIVIDUAL' && (name || '').indexOf(search) >= 0)).map((item, index) => (
                        renderNavigationItem(item, index)
                    ))
                ) : (
                    <div>
                        <br/>
                        <div>None hosted yet. <a href='https://www.loom.com/share/3e40df9c7ef445e98deebe435a657707' target='_blank'>Learn how to</a></div>
                        <br/>
                    </div>
                )}
            </NavigationGroup>
            {Object.values(eventsCreatedByMe.filter((({eventType, name}) => eventType === 'ORGANIZATION' && (name || '').indexOf(search) >= 0)).reduce((object, item) => {
                if (!object[item.creatorOrganization.name]) {
                    object[item.creatorOrganization.name] = {
                        name: item.creatorOrganization.name,
                        items: []
                    };
                }
                object[item.creatorOrganization.name].items.push(item);
                return object;
            }, {})).map((item, index) => (
                <NavigationGroup
                    key={index}
                    title={`${localization.webinarHosted} ${item.name}`}
                    collapsable
                    opened={openedGroup === item.name}
                    setOpened={() => setOpenedGroup(openedGroup === item.name ? null : item.name)}
                >
                    {item.items.map((item, index) => (
                        renderNavigationItem(item, index)
                    ))}
                </NavigationGroup>
            ))}
            <NavigationGroup
                title={`${localization.webinarAttended} ${localization.me}`}
                collapsable
                opened={openedGroup === 'attended'}
                setOpened={() => setOpenedGroup(openedGroup === 'attended' ? null : 'attended')}
            >
                {eventsAttendedByMe.length ? (
                    eventsAttendedByMe.map((item, index) => (
                        renderNavigationItem(item, index)
                    ))
                ) : (
                    <div>
                        <br/>
                        <div>None attended yet. <a href='https://www.loom.com/share/24bb802fb9f84ec9a4e92017eecd1822' target='_blank'>Learn how to</a></div>)
                        <br/>
                    </div>
                )}
            </NavigationGroup>
        </div>
    );
};

export default GridView;
