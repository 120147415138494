import React, {useContext, useEffect, useRef, useState} from "react";
import ModalCentral from "../../../components/modal/ModalCentral";
import localization from "../../../localization";
import InputPair from "../../../components/form/InputPair";
import Dropdown from "../../../components/content/Dropdown";
import api from "../../../api";
import {store} from "../../../store";


const ResourcesCreate = ({handleClose, setErrorToast, setConfirmationToast, onSubmit, webinarId, categoryId, resource}) => {
    const [formData, setFormData] = useState(resource);
    const [videoFile, setVideoFile] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [wordFile, setWordFile] = useState(null);
    const {currentUser} = useContext(store);
    const videoUpload = useRef();
    const pdfUpload = useRef();
    const wordUpload = useRef();
    return (
        <ModalCentral
            title={resource.id ? localization.webinarResourcesCreateEdit : localization.webinarResourcesCreateTitle}
            submitLabel={resource.id ? localization.save : localization.create}
            handleClose={handleClose}
            onSubmit={() => {
                if (videoFile || pdfFile || wordFile) {
                    const data = new FormData();
                    data.append('file', videoFile || pdfFile || wordFile);
                    data.append('userId', currentUser.id);
                    api.post(`/api/v1/files/${videoFile ? 'video' : (pdfFile ? 'pdf' : 'docx')}`, data).then(({data}) => {
                        api[resource.id ? 'put' : 'post'](`/api/v1/event/${webinarId}/resource/category/${categoryId}/link${resource.id ? `/${resource.id}` : ''}`, {
                            name: formData.name,
                            tags: formData.tags,
                            url: data.presignedUrl,
                            resourceType: videoFile ? 'VIDEO' : (pdfFile ? 'PDF' : 'DOCX')
                        }).then(({data}) => {
                            setConfirmationToast(`${formData.name} ${localization.webinarResourcesVideoSuccess}`);
                            setTimeout(() => setConfirmationToast(null), 3000);
                            setVideoFile(null);
                            setPdfFile(null);
                            setWordFile(null);
                            setFormData({name: '', url: ''});
                            onSubmit(data);
                            handleClose();
                        }).catch(() => {
                            setErrorToast(localization.webinarResourcesVideoFail);
                            setTimeout(() => setErrorToast(null), 3000);
                        });
                    }).catch(() => {
                        setErrorToast(localization.webinarResourcesVideoFail);
                        setTimeout(() => setErrorToast(null), 3000);
                    });
                } else {
                    api[resource.id ? 'put' : 'post'](`/api/v1/event/${webinarId}/resource/category/${categoryId}/link${resource.id ? `/${resource.id}` : ''}`, formData).then(({data}) => {
                        setConfirmationToast(`${formData.name} ${localization.webinarResourcesVideoSuccess}`);
                        setTimeout(() => setConfirmationToast(null), 3000);
                        setFormData({name: '', url: ''});
                        onSubmit(data);
                        handleClose();
                    });
                }
            }}
            disabled={!formData.name || !formData.url}
        >
            <InputPair
                label={localization.webinarResourcesCreateName}
                value={formData.name}
                onChange={(value) => setFormData({...formData, name: value})}
                required
            />
            <br/>
            <InputPair
                label={localization.webinarResourcesCreateUrl}
                value={formData.url}
                onChange={(value) => setFormData({...formData, url: value})}
                placeholder={localization.webinarResourcesCreateLink}
                disabled={videoFile || pdfFile}
                required
            />
            <br/>
            <div className='resources--upload'>
                <div className='flex-center'>
                    <Dropdown
                        button={localization.webinarResourcesCreateAttach}
                    >
                        <button
                            onClick={() => {
                                videoUpload.current.click();
                            }}
                        >
                            <img alt='video-gray' src='/images/video-gray.svg' />
                            {localization.webinarResourcesVideo}
                        </button>
                        <button
                            onClick={() => {
                                pdfUpload.current.click();
                            }}
                        >
                            <img alt='video-gray' src='/images/pdf.svg' />
                            {localization.webinarResourcesPdf}
                        </button>
                        <button
                            onClick={() => {
                                wordUpload.current.click();
                            }}
                        >
                            <img alt='video-gray' src='/images/word.png' />
                            {localization.webinarResourcesWord}
                        </button>
                    </Dropdown>
                    <div className='resources--upload--description'>{localization.webinarResourcesCreateAttachDescription}</div>
                </div>
                <input
                    ref={videoUpload}
                    type='file'
                    accept='video/mp4'
                    className='file-upload'
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file.size > 100 * 1024 * 1024) {
                            setErrorToast(localization.webinarResourcesCreateAttachError);
                            setTimeout(() => setErrorToast(null), 3000);
                        } else {
                            setVideoFile(file);
                            setFormData({name: file.name, url: localization.webinarResourcesVideoLoaded});
                        }
                    }}
                />
                <input
                    ref={pdfUpload}
                    type='file'
                    accept='application/pdf'
                    className='file-upload'
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file.size > 100 * 1024 * 1024) {
                            setErrorToast(localization.webinarResourcesCreateAttachError);
                            setTimeout(() => setErrorToast(null), 3000);
                        } else {
                            setPdfFile(file);
                            setFormData({name: file.name, url: localization.webinarResourcesVideoLoaded});
                        }
                    }}
                />
                <input
                    ref={wordUpload}
                    type='file'
                    accept='.doc, .docx'
                    className='file-upload'
                    onChange={(event) => {
                        const file = event.target.files[0];
                        if (file.size > 100 * 1024 * 1024) {
                            setErrorToast(localization.webinarResourcesCreateAttachError);
                            setTimeout(() => setErrorToast(null), 3000);
                        } else {
                            setWordFile(file);
                            setFormData({name: file.name, url: localization.webinarResourcesVideoLoaded});
                        }
                    }}
                />
            </div>
            <br/>
            <InputPair
                label={localization.webinarResourcesCreateSearchableTags}
                value={formData.tags}
                onChange={(value) => setFormData({...formData, tags: value})}
                placeholder={localization.webinarResourcesCreateSearchableTagsPlaceholder}
            />
        </ModalCentral>
    )
};

export default ResourcesCreate;