import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';
import {store} from "../../../store";
import localization from "../../../localization";
import api from "../../../api";
import MessagingActions from "../../../components/messaging/MessagingActions";
import Dropdown from "../../../components/content/Dropdown";
import Messaging from "../../../components/messaging/Messaging";


const QnA = ({webinarName, webinarLink, webinarId, eventParticipants, selectMessage, selectPerson, loggedInUserEventParticipant}) => {
    const [messages, setMessages] = useState([]);
    const [mutedChats, setMutedChats] = useState([]);
    const {currentUser, eventNotifications, setEventNotifications} = useContext(store);
    useEffect(() => {
        const notifications = eventNotifications.filter(({objectId, notificationsType}) => objectId === webinarId && notificationsType === 'EVENT_QNA');
        if (notifications.length) {
            api.put(`/api/v1/notifications/read`, {notificationsType: 'EVENT_QNA', objectId: webinarId}).then(({data}) => {
                api.get(`/api/v1/notifications/unread`).then(({data}) => {
                    setEventNotifications(data);
                });
            });
        }
    }, []);
    useEffect(() => {
        api.get(`/api/v1/event/${webinarId}/qna`).then(({data}) => {
            setMessages(data.map((item) => ({
                ...item,
                body: item.message,
                timestamp: item.createdDate,
                user: eventParticipants.filter(({participantUser}) => participantUser.id === item.userId)[0].participantUser,
                isCreator: currentUser.id === item.userId
            })));
        });
    }, []);
    messages.sort((a, b) => {
            let comparison = 0;
            if (a.likedCount > b.likedCount) {
                comparison = -1;
            } else if (a.likedCount < b.likedCount.length) {
                comparison = 1;
            }
            return comparison;
        }
    );
    return (
        <div className='q-n-a'>
            {/*<div className='q-n-a--filters'>
                {localization.webinarQnAFilter}: {localization.webinarQnAUpwote}
            </div>*/}
            <MessagingActions>
                <Dropdown
                    button={(<img alt='elipses' src='/images/elipses-vertical.svg' />)}
                >
                    <button
                        onClick={() => {
                            // mute
                        }}
                    >
                        {mutedChats.indexOf(null) >= 0 ? localization.messagingUnmute : localization.messagingMute}
                    </button>
                </Dropdown>
            </MessagingActions>
            <Messaging
                key={messages.length}
                messages={messages}
                isWithActions
                placeholder={localization.webinarQnAPlaceholder}
                selectPerson={selectPerson}
                hoverActions={(message) => (
                    <>
                        <button
                            onClick={() => {
                                api.delete(`/api/v1/event/${webinarId}/qna/${message.id}`).then(({data}) => {
                                    setMessages(messages.filter(({id}) => message.id !== id))
                                });
                            }}
                            disabled={message.isCreator ? false : (!loggedInUserEventParticipant.host && !loggedInUserEventParticipant.admin)}
                        >
                            {localization.deletE}
                        </button>
                    </>
                )}
                actions={(message) => {
                    return (
                        <div className='q-n-a--actions'>
                            <button
                                className='q-n-a--action q-n-a--action--bordered'
                                onClick={() => {
                                    api.post(`/api/v1/event/${webinarId}/qna/${message.id}/like-unlike`, {userId: currentUser.id, liked: !message.loggedInUserLiked}).then(({data}) => {
                                        setMessages(messages.map((item) => item.id === message.id ? ({
                                            ...item,
                                            loggedInUserLiked: !item.loggedInUserLiked,
                                            likedCount: item.loggedInUserLiked ? (item.likedCount - 1) : (item.likedCount + 1)
                                        }) : item));
                                    });
                                }}
                            >
                                <div className='q-n-a--action--image'>
                                    {message.loggedInUserLiked ? (
                                        <img alt='upwote' src='/images/upwote-active.svg' />
                                    ) : (
                                        <img alt='upwote' src='/images/upwote.svg' />
                                    )}
                                </div>
                                <div>{message.likedCount} Upvotes</div>
                            </button>
                            <button
                                className='q-n-a--action'
                                onClick={() => selectMessage(message)}
                            >
                                <div className='q-n-a--action--image'>
                                    <img alt='chat' src='/images/answers.svg' />
                                </div>
                                <div>{message.childThreads.length} Answers</div>
                            </button>
                        </div>
                    );
                }}
                sendMessage={(message) => {
                    api.post(`/api/v1/event/${webinarId}/qna`, {userId: currentUser.id, message}).then(({data}) => {
                        setMessages([...messages, {
                            ...data,
                            body: data.message,
                            timestamp: data.createdDate,
                            user: currentUser
                        }])
                    });
                }}
            />
        </div>
    );
};

export default QnA;
