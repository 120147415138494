import React from 'react';
import classNames from 'classnames';


const LinkInput = ({value, image, placeholder, prefix, error, onChange, addMore, isShifted, isShowPrefix}) => {
    return (
        <div className={classNames('link-input', {'link-input--shifted': isShifted, 'link-input--error': error})}>
            <div className='link-input--image'>
                {image}
            </div>
            <input
                onChange={(event) => onChange(event.target.value)}
                value={value}
                placeholder={placeholder}
            />
           {/*{addMore ? (
                <div className='link-input--action'>
                    <a onClick={addMore}>Add more</a>
                </div>
            ) : null}*/}
            {isShowPrefix || (prefix && value) ? (
                <a
                    className={classNames('link-input--prefix', {'link-input--prefix--required': true})}
                    href={`${prefix}${value}`}
                    target='_blank'
                >
                    {prefix}{value}
                </a>
            ) : null}
            {error ? (
                <div className='link-input--message'>{error}</div>
            ) : null}
        </div>
    );
};

export default LinkInput;
