import React from 'react';
import classNames from 'classnames';

const PageContent = ({className, title, subtitle, children, dialog, action, backAction, isFullWidth, isNoPadding, isWhiteBody, isWithInfiniteScroll, noBackOnDesktop}) => {
    return (
        <div className={classNames('page-content', {'page-content--full-width': isFullWidth}, className)}>
            <div className={classNames('page-content--title', {'page-content--title--no-back': noBackOnDesktop})}>
                <button onClick={backAction}>
                    <img alt='back' src='/images/back.svg'/>
                </button>
                <div>{title}</div>
                <div className='page-content--action'>
                    {action}
                </div>
            </div>
            <div
                id='page-actions'
                className='page-content--actions'
            />
            <div className={classNames('page-content--subtitle')}>
                {subtitle}
            </div>
            <div
                id={isWithInfiniteScroll ? 'pageBody' : null}
                className={classNames('page-content--body', {'page-content--with-subtitle': subtitle, 'page-content--white-body': isWhiteBody, 'page-content--no-padding': isNoPadding})}
            >
                {children}
            </div>
            {dialog}
        </div>
    );
};

export default PageContent;