import React, {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import ContentEditable from "react-contenteditable";
import momentTimezone from "moment-timezone";
import Avatar from "../content/Avatar";
import localization from "../../localization";
import {store} from "../../store";
import Dropdown from "../content/Dropdown";
import {createLink} from "../../utils/linkUtil";



const messageContainer = {
    oldVersion: '',
    newVersion: ''
};

const Messaging = ({placeholder, isWithActions, actions, hoverActions, messages, sendMessage, selectPerson}) => {
    const [newMessage, setNewMessage] = useState('');
    const [key, setKey] = useState(null);
    const {currentUser} = useContext(store);
    const messagesContainer = useRef();
    const messagesInput = useRef();
    useEffect(() => {
        if (messagesContainer && messagesContainer.current) {
            messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight;
        }
    }, [messages]);
    return (
        <div className='messaging'>
            <div
                className='messaging--messages'
                ref={messagesContainer}
            >
                {(messages || []).map((item, index) => (
                    <div key={index}>
                        {index &&
                        (momentTimezone(messages[index - 1].timestamp).tz(currentUser.timeZoneId).startOf('day').toDate().getTime() !== momentTimezone(new Date()).startOf('day').toDate().getTime()) &&
                        (momentTimezone(item.timestamp).tz(currentUser.timeZoneId).startOf('day').toDate().getTime() === momentTimezone(new Date()).startOf('day').toDate().getTime()) ? (
                            <div className='messaging--today'>Today</div>
                        ) : null}
                        <div className='messaging--item'>
                            <div className='messaging--item--content'>
                                <Avatar
                                    className='messaging--item--image'
                                    image={item.user.image}
                                    fullName={`${item.user.firstName} ${item.user.lastName}`}
                                    onClick={() => selectPerson && selectPerson(item.user.id)}
                                />
                                <div>
                                    <div className='messaging--item--header'>
                                        <div
                                            className='messaging--item--title'
                                            onClick={() => selectPerson && selectPerson(item.user.id)}
                                        >
                                            {item.user.firstName} {item.user.lastName}
                                        </div>
                                        {!isWithActions ? (
                                            <div>{item.user.organization}</div>
                                        ) : null}
                                        {!isWithActions ? (
                                            <div>{momentTimezone(item.timestamp).tz(currentUser.timeZoneId).format('hh:mm A')}</div>
                                        ) : null}
                                    </div>
                                    <div className='messaging--item--message'>
                                        <div dangerouslySetInnerHTML={{__html: createLink((item.body || '').replace(/style="[^"]*"/g, ''))}}/>
                                    </div>
                                </div>
                            </div>
                            {isWithActions ? (
                                <div className='messaging--item--actions'>
                                    {actions(item)}
                                </div>
                            ) : null}
                            {hoverActions ? (
                                <button className='messaging--item--hover'>
                                    <Dropdown
                                        button={(<img alt='elipses' src='/images/elipses.svg' />)}
                                    >
                                        {hoverActions(item)}
                                    </Dropdown>
                                </button>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
            <div className='messaging--chat'>
                <div className='messaging--chat--content'>
                    <div className='messaging--chat--input'>
                        <ContentEditable
                            key={key}
                            html={newMessage}
                            ref={messagesInput}
                            onChange={(event) => {
                                const message = event.target.value.replace(/<[^>]*>?/gm, '') ? event.target.value : event.target.value.replace(/<[^>]*>?/gm, '');
                                setNewMessage(message);
                                messageContainer.oldVersion = messageContainer.newVersion;
                                messageContainer.newVersion = message;
                            }}
                            onKeyDown={(event) => {
                                if (!messageContainer.oldVersion && event.keyCode === 13) {
                                    event.preventDefault();
                                }
                            }}
                            onKeyUp={(event) => {
                                if (event.keyCode === 13 && !event.shiftKey && (messageContainer.oldVersion || messagesContainer.newVersion || '').replace(/<[^>]*>?/gm, '')) {
                                    sendMessage(messageContainer.oldVersion.split('<div><br></div>').join('').replace(/<br><br>/g, '<br>').replace(/<br><br>/g, '<br>'));
                                    setNewMessage('');
                                    messageContainer.oldVersion = '';
                                    messageContainer.newVersion = '';
                                    setKey(new Date().getTime());
                                    setTimeout(() => messagesInput.current.el.current.focus(), 0)
                                }
                            }}
                        />
                    </div>
                    {!newMessage ? (
                        <div className='messaging--chat--placeholder'>
                            {placeholder || 'Write a message...'}
                        </div>
                    ) : null}
                    {/*<button className='messaging--chat--emoji'>
                        <img alt='emoji' src='/images/emoji.svg' />
                    </button>*/}
                </div>
                <button
                    className={classNames('messaging--send', {'messaging--send--active': newMessage})}
                    onClick={() => {
                        if (newMessage && newMessage.replace(/<[^>]*>?/gm, '')) {
                            sendMessage(newMessage.split('<div><br></div>').join('').replace(/<br><br>/g, '<br>').replace(/<br><br>/g, '<br>'));
                            setNewMessage('');
                            messageContainer.oldVersion = '';
                            messageContainer.newVersion = '';
                            setKey(new Date().getTime());
                            setTimeout(() => messagesInput.current.el.current.focus(), 0)
                        }
                    }}
                >
                    {localization.send}
                </button>
            </div>
        </div>
    );
};

export default Messaging;
